import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import {
  DialogContent,
  ListItemSecondaryAction,
  FormControlLabel,
  DialogTitle,
  Card,
  IconButton,
  Grid,
  styled,
  ListItemButton,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import "./EventResult.css";

import TeamContextMenu from "./TeamContextMenu";
import dayjs from "dayjs";
import { setViewTeamResult } from "rx/appStateSlice";
import { withNL, WithNLProps } from "utils/NLContext";
import { Edit } from "@mui/icons-material";
import { RootState } from "rx/store";
import { setEditKPId } from "rx/appStateSlice";
import { getDatabase, ref, DatabaseReference } from "firebase/database";

import { green, red, blue } from "@mui/material/colors";
import { WithTranslation, withTranslation } from "react-i18next";
import StartFinishResultCard from "./StartFinishResultCard";
import { argouid } from "utils/SmallUtils";

const classes = {
  kpok: "kpok",
  kpbad: "kpbad",
  kptyhistatud: "kptyhistatud",
};

const KPList = styled(List)((theme) => ({
  [`& .${classes.kpok}`]: {
    backgroundColor: green[500],
  },
  [`& .${classes.kpbad}`]: {
    backgroundColor: red[500],
  },
  [`& .${classes.kptyhistatud}`]: {
    backgroundColor: blue[500],
  },
}));

const mapState = (
  state: RootState,
  ownProps: ValikTeamResultDialogOwnProps
) => ({
  eventId: state.eventId,
  archived: state.event.arch,
  ajad: state.event.ajad,
  labitudkpd: state.teamAnswers[ownProps.teamid] || {},
  answerResult: state.answerResult[ownProps.teamid] || {},
  kpList: state.kpList,
  kpData: state.kpData,
  kpAnswers: state.kpAnswers,
  team: state.teamsList[ownProps.teamid],
  isAdmin: Boolean(state.user.eventAccess),
  uid: state.user.user?.uid,
});
const mapDispatch = {
  handleClose: () => setViewTeamResult(undefined),
  setEditKPId: (kpid: string) => setEditKPId(kpid),
};
const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ValikTeamResultDialogOwnProps = {
  teamid: string;
};
type ValikTeamResultDialogProps = PropsFromRedux &
  WithNLProps &
  WithTranslation &
  ValikTeamResultDialogOwnProps;
type ValikTeamResultDialogState = {
  easyUpdate: boolean;
  initialFinishOpen: boolean;
  setstarttime: number | undefined;
  setfinishtime: number | undefined;
};

class ValikTeamResultDialog extends Component<
  ValikTeamResultDialogProps,
  ValikTeamResultDialogState
> {
  state = {
    easyUpdate: false,
    initialFinishOpen: false,
    setstarttime: undefined,
    setfinishtime: undefined,
  };

  openFB = (ref: DatabaseReference) => () => {
    window.open(ref.toString(), "_blank");
  };

  handleKPClick = (kpid: string) => (event: React.MouseEvent<HTMLElement>) => {
    const { teamid, eventId, kpAnswers, answerResult, isAdmin } = this.props;

    if (this.state.easyUpdate) {
      const c = answerResult[kpid];

      let db = getDatabase();
      let kpanswerref = ref(
        db,
        `/teams/${eventId}/kpanswers/${teamid}/${kpid}`
      );
      if (c === undefined || !c.ok) {
        const answerob = {
          korraldaja: true,
          answer: undefined as undefined | string,
        };
        if (kpAnswers[kpid]) answerob.answer = kpAnswers[kpid];
        this.props.fbUpdate(kpanswerref, answerob, "KP answer changed");
        //kpanswerref.feedbackupdate(answerob);
      } else {
        this.props.fbRemove(
          kpanswerref,
          this.props.t("kp.answer.deleted" as any)
        );
      }
    } else if (isAdmin) {
      this.props.setEditKPId(kpid);
    }
  };

  render() {
    const {
      handleClose,
      eventId,
      kpList,
      kpData,
      isAdmin,
      labitudkpd,
      kpAnswers,
      answerResult,
      team,
      teamid,
      archived,
      t,
    } = this.props;
    let orderedkpkeys = Object.keys(kpList).sort((l, r) => {
      const a = Number(kpList[l].nr);
      const b = Number(kpList[l].nr);
      if (!isNaN(a) && !isNaN(b)) return a - b;
      return kpList[l].nr.toString().localeCompare(kpList[r].nr.toString());
    });
    let teamref = ref(
      getDatabase(),
      `/teams/${this.props.eventId}/list/${this.props.teamid}`
    );
    return (
      <Dialog open={true} maxWidth={"xl"} fullWidth={true}>
        <DialogTitle>
          <TeamContextMenu tid={teamid}>
            {(onContext) => <div>{team.name}</div>}
          </TeamContextMenu>
          <Grid container spacing={2}>
            <Grid size={{ xs: 6 }}>
              <StartFinishResultCard tid={teamid} teamref={teamref} />
            </Grid>
            <Grid size="grow" alignSelf="center">
              <Card>
                <Grid
                  container
                  spacing={2}
                  sx={{ padding: 2 }}
                  alignItems="center"
                >
                  {isAdmin && !archived && (
                    <Grid>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.easyUpdate}
                            onChange={(ev) => {
                              this.setState({
                                easyUpdate: ev.target.checked,
                              });
                            }}
                            value="easyUpdate"
                          />
                        }
                        label={t("team.quickedit")}
                      />
                    </Grid>
                  )}
                  {this.props.uid === argouid && !archived && (
                    <Grid>
                      <Button
                        variant="contained"
                        onClick={this.openFB(
                          ref(
                            getDatabase(),
                            `/teams/${eventId}/kpanswers/${teamid}`
                          )
                        )}
                      >
                        Result in FB
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <KPList id="kplist">
            {orderedkpkeys
              .filter((kpid) => kpList[kpid].nr !== "F")
              .map((kpid) => {
                let l = labitudkpd[kpid];
                const ok =
                  (answerResult[kpid] &&
                    (kpList[kpid].allok || answerResult[kpid].ok)) ||
                  (l &&
                    (kpList[kpid].isauto || Boolean((kpData[kpid] || {}).a)));
                //console.log("kk", kpid, l, answerResult[kpid]);
                if (!l) l = { answer: "", aeg: undefined };
                let aeg = !l.aeg ? "--:--:--" : dayjs(l.aeg).format("HH:mm:ss");
                let kpclass = classes.kpbad;
                if (kpList[kpid].tyhistatud) kpclass = classes.kptyhistatud;
                else if (ok) kpclass = classes.kpok;
                else if (ok === undefined || l.answer === "-") kpclass = "";
                let kp = kpData[kpid];
                if (kp && kp.responses) {
                  return (
                    <ListItem
                      key={kpid}
                      dense={true}
                      disableGutters={false}
                      divider={true}
                    >
                      <ListItemButton onClick={this.handleKPClick(kpid)}>
                        <Avatar className={kpclass + " kpnr"}>
                          {kpList[kpid].nr}
                        </Avatar>
                        <ListItemText
                          className="itemtext"
                          primary={kp.responses[l.answer || ""] || "-"}
                          primaryTypographyProps={{
                            variant: "h5",
                            color: "textPrimary",
                            noWrap: true,
                          }}
                          secondary={aeg}
                        />
                        {this.state.easyUpdate && false && (
                          <ListItemSecondaryAction>
                            <IconButton size="large">
                              <Edit />
                            </IconButton>
                          </ListItemSecondaryAction>
                        )}
                      </ListItemButton>
                    </ListItem>
                  );
                }
                return (
                  <ListItem
                    classes={{ container: "classes-onekp-container-vv" }}
                    key={kpid}
                    dense={true}
                    disableGutters={false}
                    divider={true}
                  >
                    <ListItemButton onClick={this.handleKPClick(kpid)}>
                      <Avatar className={kpclass + " kpnr"}>
                        {kpList[kpid].nr}
                      </Avatar>
                      <ListItemText
                        className="itemtext"
                        primary={l.answer}
                        primaryTypographyProps={{
                          variant: "h3",

                          color: "textPrimary",
                          noWrap: true,
                        }}
                        secondary={aeg}
                      />
                      <ListItemSecondaryAction>
                        <Typography
                          className="kp"
                          variant="h4"
                          color="textSecondary"
                        >
                          {kpAnswers[kpid]}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </KPList>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connector(withNL(withTranslation()(ValikTeamResultDialog)));
