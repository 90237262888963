import React from "react";
import { RootState, useTSelector } from "rx/store";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import {
  useTranslation,
  withTranslation,
  WithTranslation,
} from "react-i18next";
import { closeDialog } from "rx/dialogsSlice";
import { createSelector } from "reselect";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { green, red } from "@mui/material/colors";
import BaseTable, { Column, ColumnShape, AutoResizer } from "react-base-table";
import "react-base-table/styles.css";

import { AnswerResult, KP, TeamList } from "rx/fbListSlices";
import { getVisitStats, makeGetResult } from "utils/ValikResultCalc";
import { setEditKPId } from "rx/appStateSlice";
import { isMobile } from "react-device-detect";

type TeamRow = {
  id: string;
  team: TeamList;
  teamname: string;
  result?: AnswerResult;
};

enum CellType {
  Visits,
  KPValues,
}
const getResult: any = makeGetResult();

const Kokku: React.FC<{ tid: string; klass: string }> = (props) => {
  const state = useSelector((state) => state);
  const result = getResult(state, props);
  return (
    <Box fontWeight="bold" fontSize="h6.fontSize" alignContent="right">
      {result.kpkokku.toString()}
    </Box>
  );
};

const HeaderCell: React.FC<{ kpid: string; kp: KP }> = ({ kpid, kp }) => {
  const dispatch = useDispatch();
  const correct = useTSelector((state) => state.kpAnswers[kpid]);
  const teamAnswers = useTSelector((state) => state.teamAnswers);
  const { t } = useTranslation();
  const allanswers = Object.values(teamAnswers).map(
    (ta) => ta[kpid] && ta[kpid].answer
  );
  const okanswer = allanswers.reduce((c, v) => (v === correct ? c + 1 : c), 0);
  const badanswers = allanswers.reduce(
    (c, v) => (v && v !== correct ? c + 1 : c),
    0
  );
  /*
        title={
        t("kpstats.headertooltip", { ok: okanswer, bad: badanswers }) || ""
      }
      */
  return (
    <Tooltip
      title={
        <React.Fragment>
          <div
            dangerouslySetInnerHTML={{
              __html: t("kpstats.headertooltip", {
                ok: okanswer,
                bad: badanswers,
              }),
            }}
          />
        </React.Fragment>
      }
    >
      <Button onClick={() => dispatch(setEditKPId(kpid))}>KP {kp.nr}</Button>
    </Tooltip>
  );
};

class StatsRenderer {
  cellType = CellType.Visits;

  doRender(
    column: ColumnShape<TeamRow>,
    rowIndex: number,
    rowData: TeamRow,
    event: any,
    visitstats: any
  ) {
    let ok =
      rowData.result && rowData.result[column.kpid]
        ? rowData.result[column.kpid].ok || column.kp.allok || false
        : undefined;
    if (this.cellType === CellType.KPValues) {
      if (ok === undefined) return "";
      let val = 0;
      let ra = column.kp.ra || 1;
      const sharedrakoefids = event.sharedrakoef?.split(",");
      const kpval = sharedrakoefids?.includes(ra.toString())
        ? Math.floor(event.rakoef[ra] / visitstats[rowData.id][column.kpid])
        : event.rakoef[ra];
      let extra = [];
      if (ok || event.answerscores) {
        val += kpval;
      }
      if (event.wrongpenaltyenabled && !column.kp.allok && !ok) {
        val -= kpval * (event.wrongcoef || 1);
        extra.push("V");
      }
      /*
            if (firstkpvisitbonus && visitstats[tid].firstkps[k]) {
              val += kpval * firstkpvisitbonus;
              extra.push("E");
            }
            */
      return val + (extra.length > 0 ? " (" + extra.join(",") + ")" : "");
    }
    if (rowData.result && rowData.result[column.kpid]) {
      return (
        <CheckCircleIcon
          fontSize="small"
          style={{
            color:
              rowData.result[column.kpid].ok || column.kp.allok
                ? green[500]
                : red[500],
          }}
        />
      );
    } else return "";
  }
}

const globalRenderer = new StatsRenderer();

const getKpList = (state: RootState) => state.kpList;
const getTeams = (state: RootState) => state.teamsList;
const getEventData = (state: RootState) => state.event;
const getKPsAndTeams = createSelector(
  [getKpList, getTeams, getEventData, getVisitStats],
  (kpList, teams, event, visitstats) => {
    let columns: ColumnShape<TeamRow>[] = [];
    columns.push({
      key: "team",
      title: "Team",
      dataKey: "teamname",
      frozen: Column.FrozenDirection.LEFT,
      width: 150,
    });

    Object.entries(kpList)
      .sort(([_a, kpa], [_b, kpb]) => {
        if (isNaN(+kpa.nr) || isNaN(+kpb.nr)) {
          return kpa.nr.toString().localeCompare(kpb.nr.toString());
        }
        return Number(kpa.nr) - Number(kpb.nr);
      })
      .forEach(([kpid, kp]) => {
        if (kp.nr === "F") return;
        columns.push({
          key: kp.nr,
          kpid: kpid,
          kp: kp,
          width: 100,
          headerRenderer: () => <HeaderCell kpid={kpid} kp={kp} />,
          cellRenderer: ({ column, rowIndex, rowData }) => {
            return globalRenderer.doRender(
              column,
              rowIndex,
              rowData,
              event,
              visitstats
            );
          },
        });
      });
    columns.push({
      key: "kokku",
      title: "Kokku",
      cellRenderer: ({ rowData }) => {
        if (!rowData.team.klassid) return null;
        return (
          <Kokku
            tid={rowData.id}
            klass={Object.keys(rowData.team.klassid)[0]}
          />
        );
      },
      frozen: Column.FrozenDirection.RIGHT,
      width: 80,
    });
    let data: TeamRow[] = [];
    Object.entries(teams).forEach(([tid, team]) => {
      if (team.disabled) return;
      data.push({
        team: team,
        teamname: team.name || "",
        id: tid,
      });
    });

    return { columns: columns, data: data };
  }
);

const getAnswerResult = (state: RootState) => state.answerResult;

const getColumnsAndData = createSelector(
  [getKPsAndTeams, getAnswerResult],
  (data, answerResult) => {
    data.data = [...data.data];
    data.data.forEach((entry: any, idx: number) => {
      if (answerResult[entry.id] === entry.result) return;
      entry.result = answerResult[entry.id];
      data.data[idx] = Object.assign({}, entry);
    });
    return data;
  }
);

const mapState = (state: RootState) => ({
  evname: state.event.name,
  answerResult: state.answerResult,
  klassid: state.event.klassid,
  columnsAndData: getColumnsAndData(state),
});
const mapDispatch = {
  closeDialog: () => closeDialog("kpStats"),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type KPStatsDialogProps = PropsFromRedux & WithTranslation & {};
type KPStatsDialogState = {
  klass: string;
  celltype: CellType;
};
class KPStatsDialog extends React.Component<
  KPStatsDialogProps,
  KPStatsDialogState
> {
  state: KPStatsDialogState = {
    klass: "-",
    celltype: CellType.Visits,
  };
  render() {
    const { t, klassid } = this.props;
    const { columns, data } = this.props.columnsAndData;

    globalRenderer.cellType = this.state.celltype;

    let filterdata = data;
    if (this.state.klass !== "-")
      filterdata = filterdata.filter((r) => {
        return this.state.klass in r.team.klassid;
      });

    return (
      <Dialog open={true} maxWidth="lg" fullWidth fullScreen={isMobile}>
        <DialogTitle>
          {t("kpstats.title", { evname: this.props.evname })}
        </DialogTitle>
        <DialogContent>
          <Container sx={{ paddingBottom: 2 }}>
            <Grid container spacing={2}>
              {klassid !== undefined && (
                <Grid>
                  <FormControl>
                    <InputLabel id="inputKlass">
                      {t("kpstats.klasslabel")}
                    </InputLabel>
                    <Select
                      variant="standard"
                      labelId="inputKlass"
                      id="klassSelect"
                      value={this.state.klass || "-"}
                      onChange={(event) => {
                        this.setState({ klass: event.target.value as string });
                      }}
                    >
                      <MenuItem value="-">
                        {t("kpstats.allcontestants")}
                      </MenuItem>
                      {Object.keys(klassid).map((klass) => (
                        <MenuItem key={klass} value={klass}>
                          {klass}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid>
                <FormControl>
                  <InputLabel id="inputKlass">
                    {t("kpstats.celltype")}
                  </InputLabel>
                  <Select
                    variant="standard"
                    labelId="cellType"
                    id="cellSelect"
                    value={this.state.celltype || CellType.Visits}
                    onChange={(event) => {
                      this.setState({
                        celltype: event.target.value as CellType,
                      });
                    }}
                  >
                    <MenuItem value={CellType.Visits}>
                      {t("kpstats.celltypevisits")}
                    </MenuItem>{" "}
                    <MenuItem value={CellType.KPValues}>
                      {t("kpstats.celltypekpvalues")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Container>

          <Container style={{ height: "70vh" }}>
            <AutoResizer>
              {({ width, height }) => (
                <BaseTable
                  fixed
                  width={width}
                  height={height}
                  columns={columns}
                  data={filterdata}
                  rowProps={{ celltype: this.state.celltype }} // this will force rerendering when type changes
                />
              )}
            </AutoResizer>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={this.props.closeDialog}
          >
            {t("button.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connector(withTranslation()(KPStatsDialog));
