import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Paper,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { RootState, useTSelector } from "rx/store";
import FBSwitch from "./FBSwitch";
import FBTextField from "./FBTextField";
import {
  defaultorderrules,
  allorderrules,
  OrderRuleKeys,
  isOrderRuleKeys,
} from "utils/orderrules";
import { useNL } from "utils/NLContext";
import { useTranslation } from "react-i18next";
import RaConfigCard from "./RaConfigCard";
import FirstBonusConfigCard from "./FirstBonusConfigCard";
import { FormControlLabel, Switch } from "@mui/material";
import { child, getDatabase, ref } from "firebase/database";
import KPGroupsConfigCard from "./KPGroupsConfigCard";

import {
  draggable,
  dropTargetForElements,
  ElementDropTargetEventBasePayload,
  monitorForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { DropIndicator } from "@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box";
import {
  attachClosestEdge,
  type Edge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";

type RuleData = {
  index: number;
  rule: OrderRuleKeys;
  usedRule: boolean;
};

function UnusedRulesBox({ rules }: { rules: OrderRuleKeys[] }) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;

    return dropTargetForElements({
      canDrop({ source }) {
        const { usedRule } = source.data;
        return typeof (usedRule) === "boolean" && usedRule;
      },
      element: el!,
      getData: () => ({ isUnusedRulesBox: true }),
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: ({ source }) => {
        setIsDraggedOver(false)
      },
    })
  }
    , []);

  let sx = isDraggedOver ? { backgroundColor: "skyblue" } : {};
  return <Paper ref={ref} sx={sx}>
    <List>
      <ListSubheader>{t("orderrules.unused")}</ListSubheader>
      {rules.map((item, idx) => (
        <RuleTile
          key={idx}
          index={idx}
          rule={item}
          usedRule={false}
        />
      ))}
    </List>
  </Paper>
}

function RuleTile({
  rule,
  index,
  usedRule,
}: {
  rule: OrderRuleKeys;
  index: number;
  usedRule: boolean;
}) {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [dragging, setDragging] = useState<boolean>(false);
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);

  useEffect(() => {
    const el = ref.current;

    function onChange({ source, self }: ElementDropTargetEventBasePayload) {
      const isSource = source.element === el;
      if (isSource) {
        setClosestEdge(null);
        return;
      }

      const closestEdge = extractClosestEdge(self.data);

      const sourceIndex = source.data.index as number;

      const isItemBeforeSource = index === sourceIndex - 1;
      const isItemAfterSource = index === sourceIndex + 1;

      const isDropIndicatorHidden =
        (isItemBeforeSource && closestEdge === "bottom") ||
        (isItemAfterSource && closestEdge === "top");

      if (isDropIndicatorHidden) {
        setClosestEdge(null);
        return;
      }

      setClosestEdge(closestEdge);
    }
    const data: RuleData = { index, rule, usedRule };

    return combine(
      draggable({
        element: el!,
        getInitialData: () => data,
        onDragStart: () => setDragging(true),
        onDrop: () => setDragging(false),
      }),
      dropTargetForElements({
        canDrop() {
          return usedRule;
        },
        element: el!,
        getData({ input }) {
          return attachClosestEdge(data, {
            element: el!,
            input,
            allowedEdges: ["top", "bottom"],
          });
        },
        onDrag: onChange,
        onDragEnter: onChange,
        onDragLeave: () => setClosestEdge(null),
        onDrop: () => {
          setClosestEdge(null);
        },
      })
    );
  }, [index, rule, usedRule]);

  return (
    <>
      <ListItem
        ref={ref}
        sx={{
          opacity: dragging ? 0.4 : 1, // Fades out the item
        }}
      >
        <ListItemText
          primary={t(`orderrules.title.${rule}`)}
          secondary={t(`orderrules.desc.${rule}`)}
        />
        {closestEdge && <DropIndicator edge={closestEdge} />}
      </ListItem>
    </>
  );
}

const OrderRulesCard: React.FC<{ evdata: string }> = ({ evdata }) => {
  const { t } = useTranslation();
  const { fbSet, fbRemove } = useNL();
  const eventorderrules = useTSelector(s => s.event.orderrules);

  let usedrules: OrderRuleKeys[] = useMemo(() => {
    return eventorderrules
      ? (eventorderrules.split(",") as OrderRuleKeys[])
      : defaultorderrules;
  }, [eventorderrules]);
  const orderrulespath = evdata + "orderrules";

  useEffect(() => {
    return monitorForElements({
      onDrop({ source, location }) {
        const target = location.current.dropTargets[0];
        if (!target) return; // Dropped outside
        const sourceRule = source.data.rule;
        if (!isOrderRuleKeys(sourceRule)) return;
        if (sourceRule === target.data.rule) return;
        let newrules = usedrules.filter((v) => v !== sourceRule);
        const targetRule = target.data.rule;
        if (isOrderRuleKeys(targetRule)) {
          const idx = newrules.indexOf(targetRule);
          if (idx !== -1) {
            const edge = extractClosestEdge(target.data);
            newrules.splice(
              idx + (edge === "bottom" ? 1 : 0),
              0,
              sourceRule
            );
          } else {
            if (!target.data.isUnusedRulesBox) return; // Target is not a rule, must be unused Box
          }
        }
        const newrulestring = newrules.join(",");
        if (newrulestring === defaultorderrules.join(",")) {
          fbRemove(orderrulespath, t("fbf.orderrules.changed"));
        } else {
          fbSet(orderrulespath, newrulestring, t("fbf.orderrules.changed"));
        }
      }
    })
  }, [usedrules, fbSet, fbRemove, orderrulespath, t]);

  return <Card>
    <CardHeader title={t("orderrules.cardtitle")} />
    <CardContent>
      <Grid container spacing={2}>
        <Grid>
          <Paper>
            <List>
              <ListSubheader>{t("orderrules.used")}</ListSubheader>
              {usedrules.map((item, idx) => (
                <RuleTile
                  key={idx}
                  index={idx}
                  rule={item}
                  usedRule
                />
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid>
          <UnusedRulesBox rules={(Object.keys(allorderrules) as OrderRuleKeys[])
            .filter((r) => !usedrules.includes(r))} />
        </Grid>
      </Grid>
    </CardContent>
  </Card>;
}

const RoadBookSwitch: React.FC<{ evdata: string }> = ({ evdata }) => {
  const { t } = useTranslation();
  const { fbSet, fbRemove } = useNL();
  const resulttype = useTSelector((state) => state.event.resulttype);
  const onChangeCallback = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const dbref = child(ref(getDatabase(), evdata), "resulttype");
      if (event.target.checked) {
        fbSet(dbref, "roadbook", t("undo.eventresulttype.roadbook.set"));
      } else {
        fbRemove(dbref, t("undo.eventresulttype.roadbook.clear"));
      }
    },
    [evdata, fbRemove, fbSet, t]
  );
  return (
    <FormControlLabel
      control={
        <Switch
          checked={resulttype === "roadbook"}
          onChange={onChangeCallback}
        />
      }
      label={t(`label.switch.roadbook`)}
    />
  );
};

const DistanceResultSettings: React.FC<{ eventId: string }> = ({ eventId }) => {
  const showdistance = useTSelector((s) => s.event.distanceinresult);

  if (!showdistance) return null;

  const evdata = ref(getDatabase(), `/eventsdata/${eventId}/data/dstresult`);
  return (
    <>
      <Grid size={{ xs: 6, sm: 4 }}>
        <FBTextField
          variant="outlined"
          aria-label="Target distance"
          id="targetdst"
          type="number"
          path={child(evdata, "targetdst")}
        />
      </Grid>
      <Grid size={{ xs: 6, sm: 4 }}>
        <FBTextField
          variant="outlined"
          aria-label="Distance steps"
          id="dststeps"
          type="number"
          path={child(evdata, "dststeps")}
        />
      </Grid>
      <Grid size={{ xs: 6, sm: 4 }}>
        <FBTextField
          variant="outlined"
          aria-label="First dst points"
          id="dstpoints"
          type="number"
          path={child(evdata, "dstpoints")}
        />
      </Grid>
      <Grid size={{ xs: 6, sm: 4 }}>
        <FBTextField
          variant="outlined"
          aria-label="Distance point steps"
          id="dstpointssteps"
          type="number"
          path={child(evdata, "dstpointssteps")}
        />
      </Grid>
    </>
  );
};

const eventswitches = [
  ["hideresults", "Hide results"],
  ["kpsinweb", "Show KP's"],
  ["hidewrongmarks", "Hide wrong responses count"],
  ["wrongpenaltyenabled", "Wrong penalty enabled"],
  ["answerscores", "Answer is always rewarded"],
  ["distanceinresult", "Distance in result"],
  ["questionsresult", "Result for Questions"],
];

const EventSettingsResult: React.FC<{ eventId: string }> = ({ eventId }) => {
  const event = useSelector((state: RootState) => state.event);
  const evdata = `/eventsdata/${eventId}/data/`;

  return (
    <Grid container direction="column">
      <Grid
        container
        spacing={2}
        alignContent="center"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid size={{ xs: 6, sm: 4 }} style={{ display: "none" }}>
          <RoadBookSwitch evdata={evdata} />
        </Grid>
        {eventswitches.map(([entid, entlabel]) => (
          <Grid size={{ xs: 6, sm: 4 }} key={entid}>
            <FBSwitch
              aria-label={entlabel}
              id={entid}
              path={`${evdata}${entid}`}
            />
          </Grid>
        ))}
        <DistanceResultSettings eventId={eventId} />
        <Grid size={{ xs: 6, sm: 4 }}>
          <FBTextField
            aria-label="points suffix"
            id="pointssuffix"
            path={`${evdata}pointssuffix`}
          />
        </Grid>
        <Grid size={{ xs: 6, sm: 4 }}>
          <FBTextField
            disabled={!event.wrongpenaltyenabled}
            variant="outlined"
            aria-label="Wrong coeficent"
            id="wrongcoef"
            type="number"
            path={`${evdata}wrongcoef`}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid>
          <RaConfigCard />
        </Grid>
        <Grid>
          <KPGroupsConfigCard />
        </Grid>
        <Grid>
          <FirstBonusConfigCard />
        </Grid>
        <Grid>
          <OrderRulesCard evdata={evdata} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EventSettingsResult;
