import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import KPDataOld from "./KPDataOld";
import { RootState, useTSelector } from "rx/store";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  darken,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEditInputCell,
  GridEditSingleSelectCell,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarProps,
  GridToolbarQuickFilter,
  useGridApiContext,
} from "@mui/x-data-grid";
import {
  child,
  get,
  getDatabase,
  push,
  ref,
  set,
  update,
} from "firebase/database";
import { useTranslation } from "react-i18next";
import {
  Cancel,
  Check,
  Delete,
  Edit,
  FileDownload,
  FileUpload,
  MoreVert,
  RestoreFromTrash,
  Save,
  Storage,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setEditKPId } from "rx/appStateSlice";
import { useNL } from "utils/NLContext";
import RestoreDeletedKPs from "./RestoreDeletedKPs";
import { useRFirebaseDb } from "utils/FirebaseDbWrapper";
import {
  ExportType,
  LatLngToString,
  Pastel8,
  StringToLatLng,
  argouid,
} from "utils/SmallUtils";
import EditResponsesInGrid from "./EditResponses";
import { blue, green, orange, pink } from "@mui/material/colors";
import { KPDataExporter } from "./KPDataExportHandler";
import KPDataImportHandler from "./KPDataImportHandler";
import dayjs from "dayjs";
import { SlideUpTransition } from "utils/DialogUtils";

export type DeletedKPEntry = {
  id: string;
  kp: RootState["kpList"][string];
  data?: RootState["kpData"][string];
  answer?: string;
};

enum ColorBy {
  NoColoring,
  Ra,
  InWork,
  Groups,
}

interface CustomToolbarProps extends GridToolbarProps {
  openOldKPData?: () => void;
  colorBy?: (by: ColorBy) => void;
}

export const kpnrcompare = (
  l: string | number | undefined,
  r: string | number | undefined
) => {
  let nl: number, nr: number;
  if (l === undefined) l = "";
  if (r === undefined) r = "";
  if (typeof l === "string") {
    if (l === "F") nl = -1;
    else nl = parseInt(l);
  } else nl = l;
  if (typeof r === "string") {
    if (r === "F") nr = -1;
    else nr = parseInt(r);
  } else nr = r;
  if (!isNaN(nl) && !isNaN(nr)) {
    let diff = nl - nr;
    if (diff !== 0) return diff;
  }
  return l.toString().localeCompare(r.toString());
};

const defaultcoloring = ColorBy.Ra;

/*
function isKeyboardEvent(event: any): event is React.KeyboardEvent {
  return !!event.key;
}
  */

export const ImportButton: React.FC = () => {
  const { t } = useTranslation();
  const [doImport, setDoImport] = useState(false);
  return (
    <>
      <Button startIcon={<FileUpload />} onClick={(event) => setDoImport(true)}>
        {t("button.doimport")}
      </Button>
      <KPDataImportHandler
        trigger={doImport}
        handleClose={() => setDoImport(false)}
      />
    </>
  );
};

export const ExportButton: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [type, setType] = useState<ExportType>();

  const apiref = useGridApiContext();
  const { t } = useTranslation();
  return (
    <>
      <Button
        startIcon={<FileDownload />}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {t("button.export")}
      </Button>
      {type && (
        <KPDataExporter
          type={type}
          onComplete={() => {
            setType(undefined);
          }}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setType("json");
          }}
        >
          <ListItemText>{t("download.asjson")}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setType("csv");
          }}
        >
          <ListItemText>{t("download.ascsv")}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setType("gpx");
          }}
        >
          <ListItemText>{t("download.asgpx")}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            apiref.current.exportDataAsPrint({
              copyStyles: true,
              hideToolbar: true,
            });
            setAnchorEl(null);
          }}
        >
          <ListItemText>Print</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
const ReenumerateDialog: React.FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [numberToAdd, setNumberToAdd] = useState(0);
  const kplist = useTSelector((s) => s.kpList);
  const evid = useTSelector((s) => s.eventId);
  const [changes, setChanges] = useState<
    {
      kpid: string;
      fromnr: number;
      tonr: number;
      conflict: boolean;
    }[]
  >([]);

  const { addUndo } = useNL();

  const handleStartChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChanges([]);
    setStart(Number(event.target.value));
  };

  const handleEndChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChanges([]);
    setEnd(Number(event.target.value));
  };

  const handleNumberToAddChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChanges([]);
    setNumberToAdd(Number(event.target.value));
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="xl"
      TransitionProps={{ unmountOnExit: true }}
      TransitionComponent={SlideUpTransition}
    >
      <DialogTitle>{t("reenumerate.title")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid>
            <TextField
              label={t("label.start")}
              type="number"
              value={start}
              onChange={handleStartChange}
              fullWidth
              margin="dense"
            />
          </Grid>
          <Grid>
            <TextField
              label={t("label.end")}
              type="number"
              value={end}
              onChange={handleEndChange}
              fullWidth
              margin="dense"
            />
          </Grid>
          <Grid>
            <TextField
              label={t("label.numtoadd")}
              type="number"
              value={numberToAdd}
              onChange={handleNumberToAddChange}
              fullWidth
              margin="dense"
            />
          </Grid>
        </Grid>
        {changes.length > 0 && (
          <List>
            {changes.map((e) => (
              <ListItem key={e.kpid}>
                <ListItemText
                  primary={
                    e.fromnr +
                    " -> " +
                    e.tonr +
                    " " +
                    (e.conflict ? t("error.cpnumconflict") : "")
                  }
                  sx={e.conflict ? { backgroundColor: "pink" } : {}}
                />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        {changes.length > 0 && changes.find((e) => e.conflict) === undefined ? (
          <Button
            onClick={() => {
              changes.forEach((e) => {
                const kpnrref = ref(
                  getDatabase(),
                  `/eventsdata/${evid ?? "noev"}/kp/${e.kpid}/nr`
                );
                set(kpnrref, e.tonr);
              });
              addUndo("kpreenum", t("change.kpreenum"), () => {
                changes.forEach((e) => {
                  const kpnrref = ref(
                    getDatabase(),
                    `/eventsdata/${evid ?? "noev"}/kp/${e.kpid}/nr`
                  );
                  set(kpnrref, e.fromnr);
                });
              });
              setChanges([]);
            }}
            variant="contained"
          >
            {t("button.doit")}
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              const c = Object.entries(kplist)
                .filter(
                  ([kpid, kp]) =>
                    !isNaN(Number(kp.nr)) &&
                    Number(kp.nr) >= start &&
                    Number(kp.nr) <= end
                )
                .map(([kpid, kp]) => ({
                  kpid: kpid,
                  fromnr: Number(kp.nr),
                  tonr: Number(kp.nr) + numberToAdd,
                  conflict: false,
                }));
              const kplistarray = Object.entries(kplist);
              const cmap: { [kpid: string]: number } = {};
              c.forEach((e) => (cmap[e.kpid] = e.tonr));
              c.forEach((e) => {
                e.conflict =
                  kplistarray.find(([kplkpid, kplkp]) => {
                    if (kplkpid === e.kpid) return false;
                    if (cmap[kplkpid] !== undefined)
                      return e.tonr === cmap[kplkpid];
                    return kplkp.nr === e.tonr;
                  }) !== undefined;
              });
              setChanges(c);
            }}
          >
            {t("button.apply")}
          </Button>
        )}
        <Button variant="contained" onClick={onClose}>
          {t("button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
function KPTableToolbar(props: any) {
  const eventId = useTSelector((s) => s.eventId) ?? "musthave";
  const deletedCPs = useRFirebaseDb<{
    [deleteid: string]: DeletedKPEntry;
  }>(`/eventsdata/${eventId}/deletedkps`);
  const [restoreKPOpen, setRestoreKPOpen] = useState(false);
  const [reenumOpen, setReenumOpen] = useState(false);
  const kpgroups = useTSelector((s) => s.event.kpgroups);
  const [usedColorBy, setUsedColorBy] = useState(defaultcoloring);
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const handleMoreMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMoreAnchorEl(event.currentTarget);
  };
  const moreMenuOpen = Boolean(moreAnchorEl);
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <ImportButton />
      <ExportButton />
      <IconButton size="small" onClick={handleMoreMenuOpen}>
        <MoreVert color="primary" />
      </IconButton>
      <Box sx={{ flex: 1 }} />
      <GridToolbarQuickFilter />
      <Menu
        anchorEl={moreAnchorEl}
        open={moreMenuOpen}
        onClose={() => setMoreAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Divider />
        <MenuItem onClick={() => setReenumOpen(true)}>
          <ListItemText inset>{t("kp.menu.reenumerate")}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMoreAnchorEl(null);
            props.openOldKPData();
          }}
        >
          <ListItemText inset>{"Open old CP data dialog"}</ListItemText>
        </MenuItem>
        <Divider />
        {[
          [ColorBy.NoColoring, t("kp.color.none")],
          [ColorBy.Ra, t("kp.color.ra")],
          [ColorBy.InWork, t("kp.color.inwork")],
          [ColorBy.Groups, t("kp.color.groups")],
        ]
          .filter((e) => kpgroups || e[0] !== ColorBy.Groups)
          .map(([by, label]) => (
            <MenuItem
              onClick={() => {
                props.colorBy(by);
                setUsedColorBy(by as ColorBy);
                setMoreAnchorEl(null);
              }}
            >
              {usedColorBy === by && (
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
              )}
              <ListItemText inset={usedColorBy !== by}>{label}</ListItemText>
            </MenuItem>
          ))}
        {Object.keys(deletedCPs || {}).length !== 0 && (
          <div>
            <Divider />

            <MenuItem
              onClick={() => {
                setMoreAnchorEl(null);
                setRestoreKPOpen(true);
              }}
            >
              <ListItemIcon>
                <RestoreFromTrash />
              </ListItemIcon>
              <ListItemText>aa {t("kpdata.restore")}</ListItemText>
            </MenuItem>
          </div>
        )}
      </Menu>
      {restoreKPOpen && deletedCPs && (
        <RestoreDeletedKPs
          deletedkps={deletedCPs}
          eventref={ref(getDatabase(), `/eventsdata/${eventId}`)}
          onClose={() => setRestoreKPOpen(false)}
        />
      )}
      <ReenumerateDialog
        isOpen={reenumOpen}
        onClose={() => setReenumOpen(false)}
      />
    </GridToolbarContainer>
  );
}

const KPData: React.FC = () => {
  const eventId = useTSelector((s) => s.eventId) ?? "musthave";
  const [useOld, setUseOld] = useState(false);
  const kpList = useTSelector((s) => s.kpList);
  const kpData = useTSelector((s) => s.kpData);
  const kpAnswers = useTSelector((s) => s.kpAnswers);
  const kpgroups = useTSelector((s) => s.event.kpgroups);
  const rakoef = useTSelector((s) => s.event.rakoef);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { execWithProgressAndUndo, alertDialog, addUndo } = useNL();
  const uid = useTSelector((s) => s.user.user?.uid);
  const [colorStyles, setColorStyles] = useState<SxProps<Theme>>({});
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const rows = Object.entries(kpList).map(([kpid, kp]) => ({
    id: kpid,
    nr: kp.nr,
    allok: kp.allok,
    isauto: kp.isauto,
    ra: kp.ra || 1,
    grp: kp.grp || 1,
    inwork: kp.inwork,
    kohustuslik: kp.kohustuslik,
    maxanswerdistance: kp.maxanswerdistance,
    noopenwhennotclose: kp.noopenwhennotclose,
    tyhistatud: kp.tyhistatud,
    wrongpenalty: kp.wrongpenalty,
    desc: kpData[kpid]?.desc,
    longdesc: kpData[kpid]?.longdesc,
    loc: kpData[kpid]?.loc,
    responses: kpData[kpid]?.responses,
    correctresp: kpAnswers[kpid],
  }));
  type RowType = (typeof rows)[number];
  //type KPGridColDef = GridColDef & { hide?: boolean }; // When using StyledDataGrid, this one has to be used
  type KPGridColDef = GridColDef<RowType>;

  const eventref = useMemo(
    () => ref(getDatabase(), `/eventsdata/${eventId}`),
    [eventId]
  );
  const deleteKPCallback = useCallback(
    (kpid: string) => (event: React.MouseEvent) => {
      event.stopPropagation();

      const deletekey = push(child(eventref, "deletedkps")).key;
      const savekp: DeletedKPEntry = {
        id: kpid,
        kp: kpList[kpid],
      };

      if (kpAnswers[kpid]) savekp.answer = kpAnswers[kpid];
      if (kpData[kpid]) savekp.data = kpData[kpid];

      // Prettier is broken if it is directly in a parameter
      const undofn = () => {
        update(eventref, {
          [`deletedkps/${deletekey}`]: null,
          [`kp/${kpid}`]: savekp.kp,
          [`kpanswer/${kpid}`]: savekp.answer,
          [`kpdata/${kpid}`]: savekp.data,
        });
      };
      execWithProgressAndUndo(
        async () => {
          update(eventref, {
            [`deletedkps/${deletekey}`]: savekp,
            [`kp/${kpid}`]: null,
            [`kpanswer/${kpid}`]: null,
            [`kpdata/${kpid}`]: null,
          });
        },
        undofn,
        t("kp.deleted")
      );
    },
    [eventref, kpList, kpAnswers, kpData, execWithProgressAndUndo, t]
  );
  const columns: KPGridColDef[] = [
    {
      field: "actions",
      headerName: t("header.actions"),
      type: "actions",
      getActions: (params) => {
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(params.id)}
            />,
            <GridActionsCellItem
              icon={<Cancel />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <Tooltip title={t("kp.tooltip.edit")}>
            <GridActionsCellItem
              icon={<Edit />}
              label="Edit"
              onClick={() => {
                dispatch(setEditKPId(params.row.id));
              }}
            />
          </Tooltip>,
          <Tooltip title={t("kp.tooltip.delete")}>
            <GridActionsCellItem
              icon={<Delete />}
              label="Delete"
              onClick={deleteKPCallback(params.row.id)}
            />
          </Tooltip>,
          ...(uid === argouid
            ? [
                <Tooltip title={t("kp.tooltip.db")}>
                  <GridActionsCellItem
                    icon={<Storage />}
                    label="Database entry"
                    onClick={() => {
                      alertDialog({
                        title: "Which database",
                        content: "Pick database location",
                        buttons: [
                          { id: "cancel", label: "cancel" },
                          { id: "kp", label: "KP List entry" },
                          { id: "kpdata", label: "KP Data entry" },
                          { id: "kpanswer", label: "KP Answer entry" },
                        ],
                      }).then((v) => {
                        if (v === "cancel") return;
                        window.open(
                          ref(
                            getDatabase(),
                            `/eventsdata/${eventId}/${v}/${params.row.id}`
                          ).toString(),
                          "_blank"
                        );
                      });
                    }}
                  />
                </Tooltip>,
              ]
            : []),
        ];
      },
    },
    {
      field: "id",
      headerName: "ID",
    },
    {
      field: "nr",
      headerName: t("kpdata.header.nr"),
      editable: true,
      sortComparator: kpnrcompare,
    },
    {
      field: "ra",
      editable: true,
      type: "singleSelect",
      valueOptions(params) {
        return Object.keys(rakoef || { 1: true });
      },
      headerName: t("kpdata.header.ra"),
    },
    {
      field: "wrongpenalty",
      type: "number",
      editable: true,
      headerName: t("kpdata.header.wrongpenalty"),
    },
    {
      field: "grp",
      editable: true,
      type: "singleSelect",
      valueOptions(params) {
        return Object.keys(kpgroups || { 1: true });
      },
      headerName: t("kpdata.header.grp"),
    },
    {
      field: "inwork",
      type: "boolean",
      editable: true,
      headerName: t("kpdata.header.inwork"),
    },
    {
      field: "desc",
      headerName: t("kpdata.header.desc"),
      editable: true,
    },
    {
      field: "longdesc",
      headerName: t("kpdata.header.longdesc"),
      editable: true,
    },
    {
      field: "allok",
      type: "boolean",
      editable: true,
      headerName: t("kpdata.header.allok"),
    },
    {
      field: "isauto",
      type: "boolean",
      editable: true,
      headerName: t("kpdata.header.autokp"),
    },
    {
      field: "kohustuslik",
      type: "boolean",
      editable: true,
      headerName: t("kpdata.header.mandatory"),
    },
    {
      field: "tyhistatud",
      type: "boolean",
      editable: true,
      headerName: t("kpdata.header.tyhistatud"),
    },
    {
      field: "maxanswerdistance",
      type: "number",
      editable: true,
      headerName: t("kpdata.header.maxanswerdistance"),
    },
    {
      field: "noopenwhennotclose",
      type: "boolean",
      editable: true,
      headerName: t("kpdata.header.noopenwhennotclose"),
    },
    {
      field: "loc",
      editable: true,
      valueGetter: (_, row) => LatLngToString(row.loc),
      valueSetter: (v, row) => {
        const loc = StringToLatLng(v);
        if (loc) return { ...row, loc };
        else return { ...row };
      },
      headerName: t("kpdata.header.loc"),
    },
    {
      field: "correctresp",
      editable: true,
      type: "singleSelect",
      valueOptions: (params) =>
        Object.entries(params.row?.responses ?? {}).map(([rid, resp]) => ({
          value: rid,
          label: resp,
        })),
      valueFormatter: (_, row) => {
        if (row.responses && row.correctresp) {
          if (row.correctresp in row.responses)
            return row.responses[row.correctresp];
          else return `BAD: ${row.correctresp}`;
        }
        return row.correctresp;
      },
      cellClassName: (params) => {
        return params.row.correctresp &&
          params.row.responses &&
          !(params.row.correctresp in params.row.responses)
          ? "badresponse"
          : "";
      },
      renderEditCell(params) {
        if (
          params.row.responses &&
          (!params.row.correctresp ||
            params.row.responses[params.row.correctresp])
        )
          return <GridEditSingleSelectCell {...params} />;
        else return <GridEditInputCell {...params} />;
      },
      headerName: t("kpdata.header.correctresp"),
    },
    {
      field: "responses",
      type: "string",
      editable: true,
      valueFormatter: (_, row) =>
        row.responses ? Object.values(row.responses).join(", ") : "",
      valueSetter: (value, row) => {
        const responses = value;
        return { ...row, responses };
      },
      renderEditCell: (params) => <EditResponsesInGrid {...params} />,
      headerName: t("kpdata.header.responses"),
    },
  ];

  const colorBy = useCallback((by: ColorBy) => {
    if (by === ColorBy.NoColoring) {
      setColorStyles({});
    } else if (by === ColorBy.Ra) {
      setColorStyles({
        "& .MuiDataGrid-row--editing": {
          bgcolor: "inherit",
        },
        ...Pastel8.reduce(
          (acc, color, index) => ({
            ...acc,
            [`& .ra${index + 1}`]: {
              bgcolor: color,
              "&:hover": { bgcolor: darken(color, 0.1) },
            },
          }),
          {}
        ),
        "& .rax": {
          bgcolor: orange[200],
          "&:hover": { bgcolor: orange[300] },
        },
      });
    } else if (by === ColorBy.Groups) {
      setColorStyles({
        "& .MuiDataGrid-row--editing": {
          bgcolor: "inherit",
        },
        ...Pastel8.reduce(
          (acc, color, index) => ({
            ...acc,
            [`& .grp${index + 1}`]: {
              bgcolor: color,
              "&:hover": { bgcolor: darken(color, 0.1) },
            },
          }),
          {}
        ),
        "& .grpx": {
          bgcolor: orange[200],
          "&:hover": { bgcolor: orange[300] },
        },
      });
    } else if (by === ColorBy.InWork) {
      setColorStyles({
        "& .MuiDataGrid-row--editing": {
          bgcolor: "inherit",
        },
        "& .kpready": {
          bgcolor: green[200],
          "&:hover": { bgcolor: green[300] },
        },
        "& .inwork": {
          bgcolor: pink[100],
          "&:hover": { bgcolor: pink[200] },
        },
      });
    }
  }, []);
  useEffect(() => {
    colorBy(defaultcoloring);
  }, [colorBy]);
  const toolbarProps: CustomToolbarProps = {
    showQuickFilter: true,
    openOldKPData: () => setUseOld(true),
    colorBy: colorBy,
  };
  return (
    <DialogContent
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          height: "100%",
          ...colorStyles,
          "& .badresponse": { bgcolor: "pink" },
          "& .tyhistatud": {
            bgcolor: blue[200],
            "&:hover": { bgcolor: darken(blue[200], 0.1) },
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          rowModesModel={rowModesModel}
          onRowModesModelChange={(params) => setRowModesModel(params)}
          onRowEditStop={(params, event) => {
            if (params.field === "responses") {
              event.defaultMuiPrevented = true;
              return;
            }
            if (params.reason === GridRowEditStopReasons.rowFocusOut) {
              event.defaultMuiPrevented = true;
            }
          }}
          editMode="row"
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
            sorting: {
              sortModel: [{ field: "nr", sort: "asc" }],
            },
            columns: {
              columnVisibilityModel: {
                id: false,
                db: false,
                ra: true,
                grp: false,
                inwork: false,
                isauto: false,
                wrongpenalty: false,
                kohustuslik: false,
                tyhistatud: false,
                longdesc: false,
                maxanswerdistance: false,
                noopenwhennotclose: false,
                loc: false,
                responses: false,
                correctresp: true,
              },
            },
          }}
          pageSizeOptions={[5, 10, 50, 100]}
          disableRowSelectionOnClick
          autosizeOnMount
          autosizeOptions={{
            columns: ["actions", "id", "nr", "desc", "longdesc"],
            includeOutliers: true,
            includeHeaders: true,
          }}
          getRowClassName={(params) => {
            const resp: string[] = [];
            const ra = params.row.ra;
            if (ra > 0 && ra < 9) resp.push(`ra${ra}`);
            else resp.push("rax");
            const grp = params.row.grp;
            if (grp > 0 && grp < 9) resp.push(`grp${grp}`);
            else resp.push("grpx");
            if (params.row.inwork) resp.push("inwork");
            else resp.push("kpready");
            if (params.row.tyhistatud) resp.push("tyhistatud");
            return resp.join(" ");
          }}
          slots={{
            toolbar: KPTableToolbar,
          }}
          slotProps={{
            toolbar: toolbarProps,
            columnsManagement: {
              getTogglableColumns: (columns) =>
                (kpgroups
                  ? columns
                  : columns.filter((c) => c.field !== "grp")
                ).map((c) => c.field),
            },
          }}
          processRowUpdate={(newRow, oldRow) => {
            const fields: [keyof (typeof rows)[number], string][] = [
              ["nr", "kp"],
              ["wrongpenalty", "kp"],
              ["inwork", "kp"],
              ["allok", "kp"],
              ["isauto", "kp"],
              ["kohustuslik", "kp"],
              ["tyhistatud", "kp"],
              ["grp", "kp"],
              ["ra", "kp"],
              ["noopenwhennotclose", "kp"],
              ["maxanswerdistance", "kp"],
              ["desc", "kpdata"],
              ["longdesc", "kpdata"],
              ["loc", "kpdata"],
            ];
            const updates: { [key: string]: any } = {};
            fields.forEach(([field, dbpath]) => {
              if (
                field === "loc" &&
                oldRow[field]?.lat === newRow[field]?.lat &&
                oldRow[field]?.lng === newRow[field]?.lng
              )
                return;
              if (oldRow[field] === newRow[field]) return;

              updates[dbpath + "/" + newRow.id + "/" + field] = newRow[field];
            });
            if (oldRow.correctresp !== newRow.correctresp) {
              updates[`kpanswer/${newRow.id}`] = newRow.correctresp;
            }
            if (oldRow.responses !== newRow.responses) {
              const keys = new Set([
                ...Object.keys(oldRow.responses ?? {}),
                ...Object.keys(newRow.responses ?? {}),
              ]);
              keys.forEach((k) => {
                const v1 = oldRow?.responses?.[k];
                const v2 = newRow?.responses?.[k];
                if (v1 === v2) return;
                if (v2) {
                  updates[`kpdata/${newRow.id}/responses/${k}`] = v2;
                } else {
                  updates[`kpdata/${newRow.id}/responses/${k}`] = null;
                }
              });
            }
            if (Object.keys(updates).length > 0) {
              let undoupdates: { [key: string]: any } = {};
              const promises = Object.keys(updates).map((k) =>
                get(child(eventref, k)).then((v) => (undoupdates[k] = v.val()))
              );
              Promise.all(promises).then(() => {
                update(eventref, updates);
                addUndo(
                  "kpchanged" + dayjs().valueOf,
                  t("kp.changed" as any),
                  () => {
                    update(eventref, undoupdates);
                  }
                );
              });
            }
            return newRow;
          }}
        />
        {useOld && eventId && (
          <KPDataOld
            eventId={eventId}
            switchToNextGen={() => setUseOld(false)}
          />
        )}
      </Box>
    </DialogContent>
  );
};

export default KPData;
