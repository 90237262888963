import { Grid } from "@mui/material";
import React from "react";
import FBSwitch from "./FBSwitch";
import FBTextField from "./FBTextField";

const EventSettingsApp: React.FC<{ eventId: string }> = ({ eventId }) => (
  <Grid container spacing={5}>
    <Grid>
      <FBTextField
        id="globmaxanswerdistance"
        type="number"
        path={`/eventsdata/${eventId}/data/maxmarkingdistance`}
      />
    </Grid>
    <Grid>
      <FBSwitch
        id="noopenwhennotclose"
        path={`/eventsdata/${eventId}/data/nokpopenwhennotclose`}
      />
    </Grid>
    <Grid>
      <FBSwitch
        id="kpdataclosed"
        path={`/eventsdata/${eventId}/data/kpdataclosed`}
      />
    </Grid>
  </Grid>
);

export default EventSettingsApp;
