import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { ResourceKey } from "i18next";
import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FBTextField from "./FBTextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { setRichEditPath } from "rx/appStateSlice";
import { Edit, Save } from "@mui/icons-material";
import { Checkbox, DialogContent, FormControlLabel } from "@mui/material";
import Grid2 from "@mui/material/Grid";
import { useNL } from "utils/NLContext";
import { getDatabase, onValue, ref } from "firebase/database";

const LngEditor: React.FC<{ lng: string; currentKey: string | null }> = ({
  lng,
  currentKey,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [rerendervalue, doReRender] = useState(1);
  const dispatch = useDispatch();
  const { fbSet } = useNL();
  useEffect(() => {
    if (currentKey === null) setValue("");
    else setValue(t(currentKey as any, { lng: lng }));
  }, [currentKey, lng, setValue, t]);
  const path = `/locales/${lng}/translation/${currentKey?.replaceAll(
    ".",
    "/"
  )}`;
  useEffect(() => {
    onValue(ref(getDatabase(), path), (s) => {
      if (s.exists()) setValue(s.val());
    });
  }, [path, setValue]);

  return (
    <>
      <TextField
        label={lng}
        multiline
        disabled={currentKey === null}
        maxRows={5}
        fullWidth
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value);
        }}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="end">
                {t(currentKey as any, { lng: lng }) !== value ? (
                  <IconButton
                    onClick={() => {
                      fbSet(path, value).then(() => {
                        doReRender(rerendervalue + 1);
                      });
                    }}
                    size="large"
                  >
                    <Save />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      fbSet(path, value);
                      dispatch(setRichEditPath(path));
                    }}
                    size="large"
                  >
                    <Edit />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          },
        }}
      />
    </>
  );
};
const TranslatorTool: React.FC = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [onlynew, setOnlyNew] = useState(false);
  const [currentKey, setKey] = useState<string | null>(null);
  const [uselngs] = useState(["en", "et-EE"]);
  const op = useMemo(() => {
    let keys: { [k: string]: boolean } = {};
    if (i18n.options.resources === undefined) return [];
    const genAllOptions = (ob: ResourceKey, prefix: string) => {
      Object.entries(ob).forEach(([k, v]) => {
        if (typeof v === "string") {
          const istranslated = `${prefix}.${k}` !== v;
          if (keys[`${prefix}.${k}`] === undefined || !istranslated)
            keys[`${prefix}.${k}`] = istranslated;
        } else {
          genAllOptions(v, prefix.length === 0 ? k : `${prefix}.${k}`);
        }
      });
    };
    Object.entries(i18n.options.resources).forEach(([l, ld]) => {
      if (uselngs.includes(l)) genAllOptions(ld["translation"], "");
    });
    return Object.entries(keys).map(([k, v]) => {
      return {
        label: k,
        untranslated: !v,
      };
    });
  }, [i18n.options.resources, uselngs]);

  const options = onlynew ? op.filter((e) => e.untranslated) : op;

  return (
    <DialogContent>
      <Grid2 container spacing={2}>
        <Grid2>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            freeSolo={!onlynew}
            options={options}
            sx={{ width: 300 }}
            onChange={(ev, value) => {
              if (value === null || typeof value === "string") {
                setKey(value);
              } else {
                setKey(value.label);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Translatable entry" />
            )}
          />
        </Grid2>
        <Grid2>
          <FormControlLabel
            control={
              <Checkbox
                checked={onlynew}
                onChange={() => setOnlyNew(!onlynew)}
              />
            }
            label="Only new"
          />
        </Grid2>
        {uselngs.map((lng) => (
          <Grid2 size={{ xs: 12 }} key={lng}>
            {true ? (
              <LngEditor lng={lng} currentKey={currentKey} />
            ) : (
              <FBTextField
                id={`translate${lng}`}
                label={lng}
                path={
                  `/locales/${lng}/translation/` +
                  currentKey?.replaceAll(".", "/")
                }
                multiline
                variant="outlined"
                maxRows={5}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="edit long description in Rich editor"
                        onClick={() => {
                          dispatch(
                            setRichEditPath(
                              `/locales/${lng}/translation/something`
                            )
                          );
                        }}
                        size="large"
                      >
                        <Edit />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Grid2>
        ))}
      </Grid2>
    </DialogContent>
  );
};

export default TranslatorTool;
