import { Check, Delete } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import dayjs from "dayjs";
import { getAuth } from "firebase/auth";
import {
  child,
  DatabaseReference,
  equalTo,
  get,
  getDatabase,
  onValue,
  orderByChild,
  query,
  ref,
  remove,
  set,
  startAfter,
  Unsubscribe,
} from "firebase/database";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showCupResult } from "rx/appStateSlice";
import { EventType } from "rx/fbListSlices";
import { useTSelector } from "rx/store";
import { NutiDialogTitle } from "utils/DialogUtils";
import { useFirebaseDb, useRFirebaseDb } from "utils/FirebaseDbWrapper";
import { useNL } from "utils/NLContext";
import AdminsAutocomplete from "./AdminsAutocomplete";
import FBSwitch from "./FBSwitch";
import FBTextField from "./FBTextField";

const useFbLink = (linkname: string | undefined) => {
  const [v, setv] = useState<boolean | undefined>(undefined);
  useEffect(() => {
    setv(undefined);
    let onv: Unsubscribe | undefined = undefined;
    let to: NodeJS.Timeout | undefined = undefined;
    if (linkname !== undefined && linkname !== "") {
      to = setTimeout(() => {
        console.log("in timeout");
        to = undefined;
        onv = onValue(ref(getDatabase(), `/cups/list/${linkname}`), (s) => {
          console.log("onvalue", s.exists());
          setv(!s.exists());
        });
      }, 500);
    }
    return () => {
      console.log("in effect clear", to);
      if (to) clearTimeout(to);
      if (onv) onv();
    };
  }, [linkname, setv]);
  console.log("check fb link with ", linkname);
  return v;
};
const CreateNewCup: React.FC<
  DialogProps & {
    onClose: () => void;
    onCreate: (name: string, link: string, year: number) => void;
  }
> = (props) => {
  const { t } = useTranslation();
  const [firstevyear, setFirstEvYear] = useState(dayjs().year());
  const [linkname, setLinkName] = useState<string>();
  const [cupName, setCupName] = useState("");
  const fblink = useFbLink(linkname);

  let errlink: string | undefined = undefined;
  if (linkname === "") errlink = t("cup.error.emptylink");
  if (fblink === false) errlink = t("cup.error.usedlink");

  return (
    <Dialog open={props.open}>
      <NutiDialogTitle onClose={props.onClose}>
        {t("cup.createcup.title")}
      </NutiDialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid>
            <TextField
              autoFocus
              margin="dense"
              id="cupname"
              label={t("cup.seriesname")}
              type="text"
              fullWidth
              value={cupName}
              onChange={(event) => setCupName(event.target.value)}
              variant="standard"
            />
          </Grid>
          <Grid>
            <TextField
              autoFocus
              margin="dense"
              id="cupname"
              label={t("cup.linkname")}
              type="text"
              fullWidth
              onChange={(event) => {
                setLinkName(event.target.value);
              }}
              helperText={errlink}
              error={Boolean(errlink)}
              variant="standard"
            />
          </Grid>
          <Grid>
            <TextField
              autoFocus
              margin="dense"
              id="cupname"
              label={t("cup.firsteventyear")}
              type="number"
              value={firstevyear}
              onChange={(event) => setFirstEvYear(parseInt(event.target.value))}
              fullWidth
              variant="standard"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={!fblink || cupName === ""}
          onClick={() =>
            props.onCreate(cupName, linkname || "brokenlink", firstevyear)
          }
        >
          {t("button.save")}
        </Button>
        <Button variant="contained" onClick={props.onClose}>
          {t("button.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CupEvListener: React.FC<{
  evid: string;
  onData: (evid: string, d: EventType | null) => void;
}> = React.memo(({ evid, onData }) => {
  useEffect(() => {
    const unsub = onValue(ref(getDatabase(), `/events/${evid}`), (s) => {
      console.log("evdata", evid);
      onData(evid, s.val());
    });
    return () => {
      onData(evid, null);
      unsub();
    };
  }, [evid, onData]);
  return null;
});

export const useCupEventIds = (cupid: string): [string[], boolean] => {
  const [mainevids, setmainevids] = useState<string[]>();
  const [secevids, setsecevids] = useState<string[]>();
  const [allids, setallids] = useState<string[]>([]);

  useEffect(() => {
    const q = query(
      ref(getDatabase(), "/cups/cupevents"),
      orderByChild("m"),
      equalTo(cupid)
    );
    const unsub = onValue(q, (s) => {
      setmainevids(Object.keys(s.val() || {}));
    });
    return unsub;
  }, [cupid]);
  useEffect(() => {
    const q = query(
      ref(getDatabase(), "/cups/cupevents"),
      orderByChild("s"),
      startAfter(null)
    );
    const unsub = onValue(q, (s) => {
      const secevs: string[] = [];
      s.forEach((c) => {
        if (c.child("s").child(cupid).exists() && c.key) secevs.push(c.key);
      });
      setsecevids(secevs);
    });
    return unsub;
  }, [cupid]);
  useEffect(() => {
    setallids((mainevids || []).concat(secevids || []));
  }, [mainevids, secevids]);
  return [allids, mainevids !== undefined && secevids !== undefined];
};

const eventToCupHandler = (evid: string, cupid: string, add: boolean) => () => {
  const cupevref = ref(getDatabase(), `/cups/cupevents/${evid}`);
  get(cupevref).then((s) => {
    if (add) {
      if (s.child("m").exists()) {
        set(child(cupevref, `s/${cupid}`), "X");
      } else {
        set(child(cupevref, "m"), cupid);
      }
    } else {
      if (s.child("m").val() === cupid) {
        remove(child(cupevref, "m")); // If there are s eelements move to m
      } else {
        remove(child(cupevref, `s/${cupid}`));
      }
    }
  });
};

const CurrEvButtonGrid: React.FC<{
  cupevids: string[];
  haveallcupevids: boolean;
  cupid: string;
}> = ({ cupevids, haveallcupevids, cupid }) => {
  const currevid = useTSelector((state) => state.eventId);
  const currevname = useTSelector((state) => state.event.name);
  const { t } = useTranslation();
  if (currevid === null) return null;

  let addcurrev = true;
  if (currevid !== null) {
    if (cupevids.includes(currevid)) {
      addcurrev = false;
    }
  }

  return (
    <Grid>
      <Button
        disabled={!haveallcupevids}
        variant="contained"
        onClick={eventToCupHandler(currevid, cupid, addcurrev)}
      >
        {t(addcurrev ? "cup.button.addcurrev" : "cup.button.rmcurrev", {
          evname: currevname,
        })}
      </Button>
    </Grid>
  );
};
export const EvListFromEvIds: React.FC<{
  evids: string[];
  onData: (data: { [k: string]: EventType }) => void;
}> = React.memo(({ evids, onData }) => {
  const [evdata, setEvData] = useState<{ [k: string]: EventType }>({});
  useEffect(() => {
    onData(evdata);
  }, [evdata, onData]);
  const evdataCallback = useCallback((evid: string, data: EventType | null) => {
    setEvData((state) => {
      if (data == null) {
        const resp = Object.assign({}, state);
        delete resp[evid];
        return resp;
      }
      return Object.assign({}, state, { [evid]: data });
    });
  }, []);
  return (
    <>
      {evids.map((e) => (
        <CupEvListener key={e} evid={e} onData={evdataCallback} />
      ))}
    </>
  );
});

const EvListWithAdminRights: React.FC<{
  cupevids: string[];
  onEventPicked: (evid: string) => void;
}> = ({ cupevids, onEventPicked }) => {
  const uid = getAuth().currentUser?.uid;
  const [events, setEvents] = useState<{ [evid: string]: EventType }>({});

  console.log("evlist for uid", uid);
  useEffect(() => {
    const evref = ref(getDatabase(), "/events");
    const qev = query(evref, orderByChild("admins/" + uid), startAfter(null));
    const unsub = onValue(qev, (s) => {
      console.log("got something", s.val());
      setEvents(s.val());
    });
    return unsub;
  }, [uid]);

  if (!uid) return <div>Missing uid</div>;

  return (
    <List>
      {Object.entries(events)
        .sort(([_l, l], [_r, r]) => (r.starttime || 0) - (l.starttime || 0))
        .filter(([ek, _]) => !cupevids.includes(ek))
        .map(([ek, ev]) => (
          <ListItem>
            <ListItemButton onClick={() => onEventPicked(ek)}>
              <ListItemText
                primary={ev.name}
                secondary={dayjs(ev.starttime || 0).format("DD MMMM YYYY")}
              />
            </ListItemButton>
          </ListItem>
        ))}
    </List>
  );
};
const PickExistingEvent: React.FC<
  DialogProps & { cupevids: string[]; onPick: (evid: string) => void }
> = (props) => {
  const { t } = useTranslation();
  const { cupevids, ...rest } = props;
  return (
    <Dialog {...rest}>
      <DialogTitle>{t("cup.dialogtitle.pickevent")}</DialogTitle>
      <DialogContent>
        <EvListWithAdminRights
          cupevids={cupevids}
          onEventPicked={props.onPick}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => props.onClose && props.onClose({}, "escapeKeyDown")}
        >
          {t("button.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const IndexEditor: React.FC<{
  initialv: number;
  onAccept: (v: number) => void;
}> = ({ initialv, onAccept }) => {
  const [value, setValue] = useState(initialv);
  return (
    <TextField
      variant="standard"
      value={value}
      type="number"
      onChange={(event) => setValue(parseInt(event.target.value))}
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => onAccept(value)} edge="end">
                <Check />{" "}
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
    />
  );
};
type CupDataEntry = {
  name?: string;
  year?: number;
  points?: number[];
};

const PointsCard: React.FC<{ points: number[]; cupref: DatabaseReference }> = ({
  points,
  cupref,
}) => {
  const { t } = useTranslation();
  const { fbSet, fbRemove } = useNL();
  const [editidx, seteditidx] = useState<number>();
  const growingpoints = useRFirebaseDb<boolean>(child(cupref, "growingpoints"));
  const addPlaceValue = useCallback(() => {
    if (points.length === 0) {
      fbSet(child(cupref, `points/0`), 25);
    } else {
      console.log("adding");
      fbSet(
        child(cupref, `points/${points.length}`),
        points[points.length - 1] - 1
      );
    }
  }, [points, cupref, fbSet]);
  const addPreset = useCallback(() => {
    let setvalue = [];
    let c = 25;
    while (c > 0) {
      setvalue.push(c);
      if (c > 18) c--;
      c--;
    }
    fbSet(child(cupref, `points/`), setvalue);
  }, [cupref, fbSet]);
  const placeString = useCallback((n: number): string => {
    return n < 0 ? "" : n > 2 ? (n + 1).toString() : "I" + placeString(n - 1);
  }, []);
  return (
    <Card>
      <CardHeader title={t("cup.title.placevalues")} />
      <CardContent>
        <Grid container spacing={2} padding={2}>
          <Grid>
            <FormControlLabel
              control={<FBSwitch path={child(cupref, "growingpoints")} />}
              label="Points 1, 2, 3, 4..."
            />
          </Grid>
        </Grid>
        {growingpoints && (
          <Grid container spacing={2}>
            <Grid>
              <FBTextField
                type="number"
                id="missvalue"
                path={child(cupref, "missvalue")}
                label={t("cup.label.missvalue")}
              />
            </Grid>
          </Grid>
        )}
        {!growingpoints && (
          <>
            <Grid container spacing={2}>
              <Grid>
                <Button variant="contained" onClick={addPlaceValue}>
                  {t("cup.button.add")}
                </Button>
              </Grid>
              <Grid>
                <Button variant="contained" onClick={addPreset}>
                  {t("cup.button.add")} 25, 22, 20, 18, 17, 16...{" "}
                </Button>
              </Grid>
            </Grid>
            <List dense>
              {points.map((v, idx) => (
                <ListItem key={idx}>
                  <ListItemIcon> {placeString(idx)}</ListItemIcon>
                  {editidx === idx ? (
                    <IndexEditor
                      initialv={v}
                      onAccept={(v) => {
                        fbSet(child(cupref, `points/${idx}`), v);
                        seteditidx(undefined);
                      }}
                    />
                  ) : (
                    <ListItemText
                      primary={v}
                      onClick={() => {
                        seteditidx(idx);
                      }}
                    />
                  )}
                  {idx === points.length - 1 && (
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => {
                          fbRemove(child(cupref, `points/${idx}`));
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          </>
        )}
      </CardContent>
    </Card>
  );
};

const CupData: React.FC<{ cupid: string }> = React.memo(({ cupid }) => {
  const [cupevids, haveallcupevids] = useCupEventIds(cupid);
  const [pickExistingOpen, setPickExistingOpen] = useState(false);
  const [evdata, setEvData] = useState<{ [k: string]: EventType }>({});
  const { t } = useTranslation();

  const cupref = useMemo(
    () => ref(getDatabase(), `cups/list/${cupid}`),
    [cupid]
  );
  const cupdata = useRFirebaseDb<CupDataEntry>(cupref);
  const evdataCallback = useCallback((data: { [k: string]: EventType }) => {
    setEvData(data);
  }, []);
  console.log("CupData render", evdata);
  return (
    <>
      <Grid container spacing={2} marginTop={1} marginBottom={2}>
        <CurrEvButtonGrid
          cupid={cupid}
          cupevids={cupevids}
          haveallcupevids={haveallcupevids}
        />
        <Grid>
          <Button variant="contained" onClick={() => setPickExistingOpen(true)}>
            {t("cup.button.addexistingev")}
          </Button>
        </Grid>
        <Grid>
          <Button variant="contained" onClick={() => {}}>
            {t("cup.button.addplaceholder")}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} paddingTop={1}>
        <Grid>
          <Card>
            <CardHeader title={t("cup.title.events")} />
            <CardContent>
              <List>
                {Object.entries(evdata).map(([k, e]) => (
                  <ListItem key={k}>
                    <ListItemText primary={e.name} />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid>
          <PointsCard points={cupdata?.points || []} cupref={cupref} />{" "}
        </Grid>
        <Grid>
          <FBTextField
            id="countingevents"
            type="number"
            path={child(cupref, "countingevents")}
          />
        </Grid>
        <EvListFromEvIds evids={cupevids} onData={evdataCallback} />
      </Grid>
      <PickExistingEvent
        open={pickExistingOpen}
        cupevids={cupevids}
        onClose={() => setPickExistingOpen(false)}
        onPick={(evid) => {
          console.log("onpick");
          eventToCupHandler(evid, cupid, true)();
          setPickExistingOpen(false);
        }}
      />
    </>
  );
});

type CupListElement = {
  name: string;
  year: number;
  admins: {
    [uid: string]: string;
  };
};
const CupMgmt: React.FC = () => {
  const { t } = useTranslation();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [currentCup, setCurrentCup] = useState("");
  const [fbcuplist] = useFirebaseDb("cups/list");
  console.log(fbcuplist);
  const dispatch = useDispatch();
  console.log("Render CupMgmt");
  const createCup = useCallback(
    (name: string, link: string, year: number) => {
      setCreateDialogOpen(false);
      set(ref(getDatabase(), `/cups/list/${link}`), {
        name: name,
        year: year,
        admins: {
          [getAuth().currentUser!.uid]: "owner",
        },
      });
      setCurrentCup(link);
    },
    [setCurrentCup]
  );
  const cuplist = (fbcuplist.value || {}) as {
    [cupid: string]: CupListElement;
  };
  return (
    <Fragment>
      <DialogContent>
        <Grid container spacing={2} alignItems="center">
          <Grid>
            <FormControl>
              <InputLabel id="currentCup">{t("cup.series.label")}</InputLabel>
              <Select
                sx={{ width: 200 }}
                labelId="currentCup"
                value={currentCup}
                label="Age"
                onChange={() => {}}
              >
                {Object.entries(cuplist)
                  .sort((l, r) =>
                    l[1].year !== r[1].year
                      ? r[1].year - l[1].year
                      : l[1].name.localeCompare(r[1].name)
                  )
                  .map(([k, e]) => (
                    <MenuItem
                      key={k}
                      value={k}
                      onClick={() => setCurrentCup(k)}
                    >
                      {e.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <Button
              variant="contained"
              onClick={() => setCreateDialogOpen(true)}
            >
              {t("cup.createCup")}
            </Button>
          </Grid>
        </Grid>
        {currentCup !== "" && (
          <Grid container marginTop={2} spacing={2}>
            <Grid>
              <Button
                variant="contained"
                onClick={() => dispatch(showCupResult(currentCup))}
              >
                {t("cup.button.cupresult")}
              </Button>
            </Grid>
            <Grid size={12}>
              <AdminsAutocomplete
                adminpath={"/cups/list/" + currentCup + "/admins"}
              />
            </Grid>
          </Grid>
        )}
        {currentCup !== "" && <CupData key={currentCup} cupid={currentCup} />}
        <CreateNewCup
          onCreate={createCup}
          onClose={() => setCreateDialogOpen(false)}
          open={createDialogOpen}
        />
      </DialogContent>
    </Fragment>
  );
};

export default CupMgmt;
