import React, { useCallback, useEffect, useState } from "react";
import store, { useTSelector } from "rx/store";
import { useTranslation } from "react-i18next";
import { AppStateDialog } from "utils/NextGenDialogFactory";
import FBManualTextField from "./FBManualTextField";
import TeamContextMenu from "./TeamContextMenu";
import StartFinishResultCard from "./StartFinishResultCard";
import { child, get, getDatabase, push, ref, remove, serverTimestamp, set } from "firebase/database";
import FBSwitch from "./FBSwitch";
import { isMobile } from "react-device-detect";
import { useNL } from "utils/NLContext";
import { useSnackbar } from "notistack";
import Grid from "@mui/material/Grid";
import TeamKPTable from "./TeamKPDataGrid";
import { TeamResultWithSummaries, getValikTeamResult } from "utils/ValikResultCalcNextGen";
import { Box, Button, Card, CardContent, DialogActions, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { clearAppStateValue } from "rx/appStateSlice";

const TeamNameTitle: React.FC<{ tid: string }> = ({ tid }) => {
  const eventId = useTSelector((state) => state.eventId);
  const { t } = useTranslation();
  return (
    <TeamContextMenu tid={tid}>
      {(onContext) => (
        <FBManualTextField
          path={"/teams/" + eventId + "/list/" + tid + "/name"}
          undoMsg={t("undomsg.teamnamechanged")}
        />
      )}
    </TeamContextMenu>
  );
};


const TeamResultSummaryCard: React.FC<{ tid: string, result: TeamResultWithSummaries }> = ({ result }) => {
  const { t } = useTranslation();
  return <Card sx={{ marginTop: 2 }}>
    <CardContent>
      <Grid container spacing={2} alignItems="center">
        <Grid >
          <Typography variant="h6">{t("title.summary.totalkp")}: {result.kokku}</Typography>
        </Grid>
        <Grid>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableBody>
                <TableRow><TableCell>{t("title.summary.kpbonus")}</TableCell><TableCell>{result.bonus}</TableCell></TableRow>
                <TableRow><TableCell>{t("title.summary.kppenalty")}</TableCell><TableCell>{result.penalty}</TableCell></TableRow>
                <TableRow><TableCell>{t("title.summary.marked")}</TableCell><TableCell>{result.marks}</TableCell></TableRow>
                <TableRow><TableCell>{t("title.summary.correct")}</TableCell><TableCell>{result.correct}</TableCell></TableRow>
                <TableRow><TableCell>{t("title.summary.wrong")}</TableCell><TableCell>{result.wrong}</TableCell></TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
}

const TeamDialogContents: React.FC<{ tid: string }> = ({ tid }) => {
  const eventId = useTSelector((state) => state.eventId);
  const [result, setResult] = useState<TeamResultWithSummaries>();

  useEffect(() => {
    const unsub = getValikTeamResult(tid, (r) => {
      setResult(r);
    })
    return unsub;
  }, [tid]);

  let teamref = ref(getDatabase(), `/teams/${eventId}/list/${tid}`);

  return (
    <>

      <Grid container spacing={2}>
        <Grid>
          <FBSwitch id="katk" path={child(teamref, "katk")} />
        </Grid><Grid>
          <FBSwitch id="dsq" path={child(teamref, "dsq")} />
        </Grid>
      </Grid>
      <StartFinishResultCard tid={tid} teamref={teamref} />

      {result && <TeamResultSummaryCard tid={tid} result={result} />}
      <TeamKPTable tid={tid} kps={result?.kps ?? []} />
    </>
  );
};

const TeamDialogActions: React.FC<{ tid: string }> = ({ tid }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deleteTeamCallback = useTeamDelete(tid);

  return <DialogActions>
    <Button variant="contained" onClick={deleteTeamCallback}>
      {t("button.delete")}
    </Button>
    <Box sx={{ flex: 1 }} />
    <Button variant="contained" onClick={() => dispatch(clearAppStateValue("editTeamId"))}>
      {t("button.close")}
    </Button>
  </DialogActions>
}

const TeamDialog: React.FC = () => (
  <AppStateDialog
    appkey="editTeamId"
    fullScreen={isMobile}
    maxWidth="md"
    fullWidth
    disableDialogActions
    titleContents={(tid: string) => <TeamNameTitle tid={tid} />}
  >
    {(tid) => <>
      <TeamDialogContents tid={tid} />
      <TeamDialogActions tid={tid} />
    </>}
  </AppStateDialog>
);

export const useTeamDelete = (tid: string) => {
  const eventId = useTSelector(s => s.eventId);
  const team = useTSelector(s => s.teamsList[tid]);
  const { alertDialog } = useNL();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const doDelete = useCallback(() => {
    if (!eventId) return;
    alertDialog({
      content: t("confirm.deleteteam.content"),
      title: t("confirm.deleteteam.title", { team: team?.name }),
    }).then((reason) => {
      if (reason !== "ok") return;
      if (store.getState().appState.editTeamId === tid) {
        dispatch(clearAppStateValue("editTeamId"));
      }
      const db = getDatabase();
      const saveref = push(
        ref(db, "/eventsdata/" + eventId + "/deletedteams")
      );
      set(child(saveref, "tid"), tid);
      set(child(saveref, "deletetime"), serverTimestamp());
      const teamsref = ref(db, `/teams/${eventId}`);

      let promlist: PromiseLike<any>[] = [];
      [
        "list",
        "data",
        "answerresult",
        "kpanswers",
        "spdtickets",
        "track",
        "trackstat",
      ].forEach((key) => {
        const keyref = child(teamsref, `${key}/${tid}`);
        promlist.push(
          get(keyref)
            .then((snap) => {
              return set(child(saveref, key), snap.val());
            })
            .then(() => {
              return remove(keyref);
            })
        );
      });
      Promise.all(promlist).then(() => {
        enqueueSnackbar("Deleted team: " + team.name, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      });
    });
  }, [alertDialog, enqueueSnackbar, eventId, t, team?.name, tid, dispatch]);
  return doDelete;
}

/*
const deleteTeam = (eventId: string, team: TeamList) => {
  if (!eventId) return;
  nl.alertDialog({
    content: t("confirm.deleteteam.content"),
    title: t("confirm.deleteteam.title", { team: team.name }),
  }).then((reason) => {
    if (reason !== "ok") return;
    const db = getDatabase();
    const saveref = push(
      dbRef(db, "/eventsdata/" + eventId + "/deletedteams")
    );
    set(child(saveref, "tid"), tid);
    set(child(saveref, "deletetime"), serverTimestamp());
    const teamsref = dbRef(db, `/teams/${eventId}`);

    let promlist: PromiseLike<any>[] = [];
    [
      "list",
      "data",
      "answerresult",
      "kpanswers",
      "spdtickets",
      "track",
      "trackstat",
    ].forEach((key) => {
      const keyref = child(teamsref, `${key}/${tid}`);
      promlist.push(
        get(keyref)
          .then((snap) => {
            return set(child(saveref, key), snap.val());
          })
          .then(() => {
            return remove(keyref);
          })
      );
    });
    Promise.all(promlist).then(() => {
      enqueueSnackbar("Deleted team: " + team.name, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    });
  });
  onClose();
};
*/
export default TeamDialog;

/*
const mapState = (state: RootState, props: TeamDialogOwnProps) => ({
  team: state.teamsList[props.teamid],
  kpAnswers: state.teamAnswers[props.teamid] || {},
  kpList: state.kpList,
});
const mapDispatch = {
  closeDialog: () => setEditTeamId(undefined),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type TeamDialogOwnProps = {
  teamid: string;
  eventId: string;
};
type TeamDialogProps = PropsFromRedux & WithTranslation & TeamDialogOwnProps;
type TeamDialogState = {
  withkpnum: boolean;
};
class TeamDialog2 extends React.Component<TeamDialogProps, TeamDialogState> {
  state: TeamDialogState = {
    withkpnum: true,
  };
  render() {
    const { team, kpAnswers, kpList } = this.props;
    console.log(kpAnswers);
    const kpval = Object.entries(kpList)
      .sort(([_a, akp], [_b, bkp]) => Number(akp.nr) - Number(bkp.nr))
      .map(([kpid, kp]) => {
        let resp = "";
        if (this.state.withkpnum) resp += kp.nr;
        if (kpAnswers[kpid]) {
          if (this.state.withkpnum) resp += "\t ";
          resp += dayjs(kpAnswers[kpid].aeg).format("HH:mm:ss");
        } else {
          if (this.state.withkpnum) resp += "\t -";
        }
        return resp;
      })
      .join("\n");

    const penaltypath =
      "/teams/" +
      this.props.eventId +
      "/list/" +
      this.props.teamid +
      "/penaltypos";
    console.log(penaltypath);
    return (
      <Dialog open={true}>
        <DialogTitle>Old dialog</DialogTitle>
        <DialogContent>
          <FBTextField
            path={penaltypath}
            type="number"
            label="Position penalty"
          />
          <Checkbox
            checked={this.state.withkpnum}
            onChange={() => {
              this.setState({ withkpnum: !this.state.withkpnum });
            }}
          />{" "}
          Koos KP numbriga
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(kpval);
            }}
            size="large"
          >
            <FileCopy />
          </IconButton>
          <Divider />
          <TextField value={kpval} multiline InputProps={{ readOnly: true }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}
*/
