import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
} from "@mui/material";
import { withTranslation, WithTranslation } from "react-i18next";
import { get, getDatabase, ref, remove, set } from "firebase/database";

const mapState = (state: RootState) => ({
  currentEventId: state.eventId,
});
const mapDispatch = {};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type UpgradeDialogProps = PropsFromRedux &
  WithTranslation & {
    onClose: () => void;
  };
type UpgradeDialogState = {
  progressValue: number;
};
class UpgradeDialog extends React.Component<
  UpgradeDialogProps,
  UpgradeDialogState
> {
  state: UpgradeDialogState = {
    progressValue: 0,
  };

  doTeamCols = async () => {
    this.setState({ progressValue: 0 });
    let db = getDatabase();
    //let evlist = await db.ref("events").once("value");
    let evlist = [{ key: this.props.currentEventId }];
    let cols = (await get(ref(db, "conf/colors"))).val();
    console.log("cols", cols);
    let colid = 0;
    let compleetedevents = 0;
    evlist.forEach((ev) => {
      if (!ev.key) return;
      let proms: Promise<any>[] = [];
      get(ref(db, `/teams/${ev.key}/list/`))
        .then((teams) => {
          teams.forEach((team) => {
            //console.log("cc", colid, cols);
            let setcol = cols[colid++];
            if (colid >= cols.length) colid = 0;
            proms.push(set(team.child("col").ref, setcol));
          });
        })
        .then(() => {
          Promise.all(proms).then(() => {
            compleetedevents++;
            this.setState({
              progressValue: (compleetedevents * 100) / 1,
            });
          });
        });
    });
  };
  doLonLng = async () => {
    this.setState({ progressValue: 0 });

    let db = getDatabase();
    //let evlist = await db.ref("events").once("value");
    let evlist = [{ key: this.props.currentEventId }];
    let compleetedevents = 0;
    evlist.forEach((ev) => {
      if (!ev.key) return;
      let proms: Promise<any>[] = [];
      get(ref(db, `/eventsdata/${ev.key}/kpdata`)).then((kps) => {
        kps.forEach((kp) => {
          let locsnap = kp.child("loc");
          if (!locsnap.child("lon").exists()) return;
          proms.push(set(locsnap.child("lng").ref, locsnap.child("lon").val()));
          proms.push(remove(locsnap.child("lon").ref));
        });
      });
      Promise.all(proms).then(() => {
        compleetedevents++;
        this.setState({
          progressValue: (compleetedevents * 100) / 1,
        });
      });
    });
  };
  render() {
    return (
      <Dialog open={true}>
        <DialogTitle>Upgrading of database</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid>
              <Button onClick={this.doLonLng} variant="contained">
                KP lon =&gt; lng
              </Button>
            </Grid>
            <Grid>
              <Button onClick={this.doTeamCols} variant="contained">
                Team colors for event
              </Button>
            </Grid>
            <Grid size={12}>
              <LinearProgress
                variant="determinate"
                value={this.state.progressValue}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connector(withTranslation()(UpgradeDialog));
