import React, { useRef, useState } from "react";
import { TrackSource } from "./TrackInvestigationDialog";
import BaseTable, { Column, ColumnShape } from "react-base-table";
import {
  Checkbox,
  Grid,
  MenuItem,
  Select,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { pointsToTrack } from "rx/tracksSlice";
import TrackBrowser from "utils/TrackBrowser";
import { useTSelector } from "rx/store";

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never;
const cellContentTypes = [
  "timestamp",
  "speed",
  "location",
  "accuracy",
] as const;
const TrackInvestigationContentsTable: React.FC<{
  sources: TrackSource[];
  tid: string;
}> = ({ sources, tid }) => {
  let tableref = useRef<BaseTable>(null);
  const team = useTSelector((state) => state.teamsList[tid]);
  let [celltype, setCellType] =
    useState<ElementType<typeof cellContentTypes>>("timestamp");
  let [currenttime, setCurrentTime] = useState<number | null>(null);
  let [visibleTracks, setVisibleTracks] = useState<string[]>([]);
  let [mapAccuracy, setMapAccuracy] = useState(0);
  let mint = 0;
  let maxt = 0;
  sources.forEach((s) => {
    if (s.points.length === 0) return;
    let min = Math.trunc(s.points[0].t / 1000);
    if (mint === 0 || min < mint) mint = min;
    let max = Math.trunc(s.points[s.points.length - 1].t / 1000);
    if (maxt === 0 || max > maxt) maxt = max;
  });
  let columns: ColumnShape<any>[] = sources.map((c) => {
    return {
      key: c.name,
      visibleTracks: visibleTracks,
      headerRenderer: ({ column }) => (
        <>
          <Tooltip title={c.name}>
            <Typography noWrap style={{ color: c.color }}>
              {c.shortname}
            </Typography>
          </Tooltip>
          <Checkbox
            style={{ color: c.color }}
            checked={column.visibleTracks.includes(c.name)}
            onChange={() => {
              column.onChange(c.name);
            }}
          />
        </>
      ),
      onChange: (v: string) => {
        const newv = [...visibleTracks];
        if (newv.includes(v)) {
          const index = newv.indexOf(v);
          if (index > -1) newv.splice(index, 1);
        } else {
          newv.push(v);
        }
        setVisibleTracks(newv);
      },
      width: 150,
      celltype: celltype,
      mapAccuracy: mapAccuracy,
      cellRenderer: ({ rowIndex, columnIndex, column }) => {
        columnIndex = columnIndex - 1;
        const points = sources[columnIndex].points;
        if (rowIndex === -1) {
          if (column.mapAccuracy === 0) return points.length;
          return (
            points.length +
            " / " +
            points.filter((p) => p.a < column.mapAccuracy).length
          );
        } else if (rowIndex === -2) {
          return <Typography noWrap> {sources[columnIndex].devid}</Typography>;
        }
        let t = (mint + rowIndex) * 1000;
        let idx;
        for (idx = 0; idx < points.length && points[idx].t < t; idx++);
        if (idx === points.length) return;
        let showpoints = [];
        while (idx < points.length && points[idx].t - t < 1000) {
          showpoints.push(points[idx++]);
        }
        return (
          <div>
            {showpoints
              .map((p) => {
                if (column.celltype === "speed") return p.s;
                else if (column.celltype === "accuracy") return p.a;
                else if (column.celltype === "location") return p.x + "," + p.y;
                else return p.t;
              })
              .join(", ")}
          </div>
        );
      },
    };
  });
  columns.unshift({
    key: "colheader",
    width: 100,
    frozen: Column.FrozenDirection.LEFT,
    cellRenderer: ({ rowIndex }) => {
      if (rowIndex === -1) return "Num points";
      else if (rowIndex === -2) return "devid";
      return dayjs((mint + rowIndex) * 1000).format("HH:mm:ss");
    },
  });

  return (
    <>
      <Select
        value={celltype}
        variant="standard"
        onChange={(event) =>
          setCellType(
            event.target.value as ElementType<typeof cellContentTypes>
          )
        }
      >
        {cellContentTypes.map((t) => (
          <MenuItem key={t} value={t}>
            {t}
          </MenuItem>
        ))}
      </Select>
      <TextField
        variant="standard"
        type="number"
        label="Map acc."
        style={{ width: "7ch" }}
        value={mapAccuracy || ""}
        onChange={(event) => setMapAccuracy(Number(event.target.value))}
      />
      <Grid container>
        <Grid size={12}>
          {sources.map((s, idx) => {
            if (s.points.length === 0) return null;
            let marks = [];
            if (idx === 0) {
              if (team.starttime)
                marks.push({ value: team.starttime / 1000, label: "S" });
              if (team.finishtime)
                marks.push({ value: team.finishtime / 1000, label: "F" });
            }
            return (
              <Tooltip title={s.shortname + " - " + s.name} key={s.name}>
                <Slider
                  marks={marks}
                  style={{ color: s.color }}
                  onChange={(_, value) => {
                    let v = (value as number[])[0];
                    let pv = s.points[0].t;
                    let dv = pv - v * 1000;

                    if (dv === 0) {
                      v = (value as number[])[1];
                      pv = s.points[s.points.length - 1].t;
                      dv = pv - v * 1000;
                    }

                    if (Math.abs(dv) / 10 / (maxt - mint) < 3) {
                      v = pv;
                    } else v *= 1000;
                    if (tableref && tableref.current) {
                      tableref.current.scrollToRow(v / 1000 - mint);
                      setCurrentTime(v);
                    }
                    console.log(dv, v, value);
                  }}
                  max={maxt}
                  min={mint}
                  value={[
                    s.points[0].t / 1000,
                    s.points[s.points.length - 1].t / 1000,
                  ]}
                />
              </Tooltip>
            );
          })}
        </Grid>
        <Grid
          sx={{
            "& .selected": {
              backgroundColor: "#e3e3e3",
            },
            "& .sf": {
              backgroundColor: "green",
            },
          }}
        >
          <BaseTable
            ref={tableref}
            selectable
            fixed
            width={400}
            height={400}
            columns={columns}
            rowClassName={({ rowIndex }) => {
              if (!currenttime) return "";
              if (rowIndex + mint === Math.trunc(currenttime / 1000))
                return "selected";
              if (
                team.starttime &&
                rowIndex + mint === Math.floor(team.starttime / 1000)
              )
                return "sf";
              if (
                team.finishtime &&
                rowIndex + mint === Math.floor(team.finishtime / 1000)
              )
                return "sf";
              return "";
            }}
            rowEventHandlers={{
              onClick: ({ rowIndex }) => {
                setCurrentTime((mint + rowIndex) * 1000);
              },
            }}
            frozenData={[{ id: "points" }, { id: "devid" }]}
            data={Array.from(Array(maxt - mint).keys()).map((e) => {
              return { id: e };
            })}
          />
        </Grid>
      </Grid>
      {visibleTracks.map((k) => {
        const src = sources.find((s) => s.name === k);
        if (!src) return null;
        return (
          <TrackBrowser
            key={k}
            track={pointsToTrack(
              src.points.filter((p) => mapAccuracy === 0 || p.a < mapAccuracy)
            )}
            color={src.color || "blue"}
            map={mainMap}
            currentTime={currenttime}
            selectTime={(t) => {
              if (tableref && tableref.current) {
                tableref.current.scrollToRow(t / 1000 - mint);
              }
              setCurrentTime(t);
            }}
          />
        );
      })}
    </>
  );
  /*
  return (
    <FixedSizeGrid
      columnCount={sources.length}
      columnWidth={100}
      height={200}
      rowCount={maxt - mint}
      rowHeight={35}
      width={400}
    >
      {({ columnIndex, rowIndex, style }) => {
        let t = (mint + rowIndex) * 1000;
        const points = sources[columnIndex].points;
        let idx;
        for (idx = 0; idx < points.length && points[idx].t < t; idx++);
        if (idx === points.length) return <div style={style}></div>;
        let showpoints = [];
        while (idx < points.length && points[idx].t - t < 1000) {
          showpoints.push(points[idx++]);
        }
        return <div style={style}>{showpoints.map((p) => p.t).join(", ")}</div>;
      }}
    </FixedSizeGrid>
  ); */
};

export default TrackInvestigationContentsTable;
