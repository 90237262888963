import { Component } from "react";
import PropTypes from "prop-types";
import { connect, ReactReduxContext } from "react-redux";
import Paper from "@mui/material/Paper";
import "./EventResult.css";
import ValikTeamResult from "./ValikTeamResult";
import {
  createSelectorCreator,
  lruMemoize,
  createSelector,
} from "reselect";
import isEqual from "lodash/isEqual";
import { allorderrules, defaultorderrules } from "utils/orderrules";
import { setViewTeamResult } from "rx/appStateSlice";
import { withTranslation } from "react-i18next";

class ValikEventResult extends Component {
  static contextType = ReactReduxContext;

  constructor(props) {
    super(props);
    this.state = {
      resultorder: props.teamsList.map((t) => t.id),
      disableAnimations: true,
    };
    this.results = {};
  }

  componentWillUnmount() {
    if (this.animationenabletimer !== undefined) {
      clearTimeout(this.animationenabletimer);
    }
    if (this.minutetimer !== undefined) {
      clearTimeout(this.minutetimer);
    }
  }

  componentDidUpdate(nextProps) {
    this.recalcOrder();
  }

  minuteUpdater = () => {
    if (this.minutetimer !== undefined) return;

    const { teamsList, eventResult } = this.props;
    if (eventResult === undefined || Object.keys(teamsList).length === 0)
      return;
    if (
      eventResult.data.find(
        (r) =>
          r.teamid in teamsList &&
          teamsList[r.teamid].finishms == null &&
          !teamsList[r.teamid].ajayletus
      ) === undefined
    )
      return;

    let ct = 60010 - (new Date().getTime() % 60000);
    this.minutetimer = setTimeout(() => {
      this.minutetimer = undefined;
      let changedkeys = this.testTimeTrahvChanges();
      if (changedkeys.length > 0) {
        this.setState((prevState, _) => {
          return { changes: prevState.changes.concat(changedkeys) };
        });
      }
      this.minuteUpdater();
    }, ct);
  };

  displayKoht(koht) {
    if (koht === 1) return <b>I</b>;
    else if (koht === 2) return <b>II</b>;
    else if (koht === 3) return <b>III</b>;
    return koht;
  }

  setTeamResult = (tid) => (result) => {
    this.results[tid] = result;
    if (this.recalctimeout) clearTimeout(this.recalctimeout);
    this.recalctimeout = setTimeout(() => {
      this.recalcOrder();
    }, 0);
  };

  recalcOrder = () => {
    let neworder = this.props.teamsList
      .sort((l, r) => {
        let lr = this.results[l.id];
        let rr = this.results[r.id];
        if (!lr || !rr) return 0;
        let resp = 0;
        this.props.orderrules.find(
          (rule) => (resp = rule.rule(lr, rr, l.id, r.id, this.context.store))
        );
        return resp;

        /*
            if (eventid < 118) {
                tmp = r.labitudkpsid - l.labitudkpsid;
                if (tmp !== 0) return tmp;
                tmp = l.goingtimes - r.goingtimes;
                if (tmp !== 0) return tmp;
            } else {
                tmp = l.goingtimes - r.goingtimes;
                if (tmp !== 0) return tmp;
                tmp = r.labitudkpsid - l.labitudkpsid;
                if (tmp !== 0) return tmp;
            }
            */
      })
      .map((t) => t.id);
    let pospenaltys = {};
    this.props.teamsList.forEach(t => {
      if (t.penaltypos) {
        pospenaltys[t.id] = t.penaltypos;
      }
    });
    if (Object.keys(pospenaltys).length > 0) {
      if (!this.state.havepospenaltys)
        this.setState({ havepospenaltys: true });
    for (let i = 0; i < neworder.length; i++) {
      if (pospenaltys[neworder[i]]) {
        let newpos = i;
        while (pospenaltys[neworder[i]] > 0) {
          newpos++;
          if (pospenaltys[neworder[newpos]] > 0){
            pospenaltys[neworder[newpos]]++;
          } else {
            pospenaltys[neworder[i]]--;
          }
        };
        if (newpos !== i){
        if (newpos > neworder.length)
          newpos = neworder.length - 1;
          neworder.splice(newpos, 0, neworder.splice(i, 1)[0]);
        }
        i--;
      }
      }
    } else {
      if (this.state.havepospenaltys)
        this.setState({ havepospenaltys: false });
    }
    if (isEqual(neworder, this.state.resultorder)) return;

    this.setState({
      resultorder: neworder,
    });
  };

  rendercount = 0;

  render() {
    const {
      eventAccess,
      eventData,
      klass,
      haveSpdTicket,
      t,
    } = this.props;

    //console.log('argo Rendercount', ++this.rendercount);

    if (!eventData) return null;

    if (!eventData.rakoef) {
      if (eventAccess) {
        return t("result.error.norakoef.admin");
      } else {
        return t("result.error.norakoef.public");
      }
    }

    if (eventData.hideresults && !eventAccess) {
      return t("result.notopen");
    }

    if (eventData.ajad === undefined) {
      return t("result.error.missingtimes");
    }
    let ajad;
    if ("normaalaeg" in eventData.ajad) ajad = eventData.ajad;
    else if (klass in eventData.ajad) ajad = eventData.ajad[klass];
    else return "Võistlus on veel seadistamata. Klassile ei ole pandud aegu.";

    const hidewrongmarks = eventData.hidewrongmarks; //(sortedresult.find(e => e.valekpcount > 0) !== undefined) && !eventData.wrongpenaltyenabled;
    const questionsresult = eventData.questionsresult;
    let kpcolspan =
      3 +
      (hidewrongmarks ? 0 : 1) +
      eventData.rakoef.filter((e) => e).length +
      (eventData.firstkpvisitbonus ? 1 : 0);
    let trahviajad =
      "normaalaeg" in ajad
        ? ajad.lisaajad || {}
        : Object.values(ajad)
            .filter((a) => a.penalty > 0)
            .sort((l, r) => l.order - r.order);
    let ajacolspan = 1 + trahviajad.length;
    let ajarowspan = 1;
    if (trahviajad.length === 1) {
      ajacolspan = 1;
      ajarowspan = 3;
    }
    let trahviajanumber = 1;
    let allowTeamClick =
      eventAccess || eventData.endtime < new Date().getTime();

    if (Object.keys(this.results).length > 0 && this.state.disableAnimations) {
      this.animationenabletimer = setTimeout(() => {
        this.setState({ disableAnimations: false });
      }, 800);
    }
    debugger;
    //console.log('render result', this.results);
    return (
      <Paper sx={{ width: "100%", overflowX: "auto",}} >
        <table className={"rtable"}>
          <thead>
            <tr>
              <td rowSpan={3}>{t("result.KOHT")}</td>
              <td rowSpan={3}>{t("result.team")}</td>
              <td colSpan={kpcolspan}>{t("result.kppoints")}</td>

              {trahviajad.length === 1 && (
                <td rowSpan={ajarowspan} colSpan={ajacolspan}>
                  <nobr>{t("result.timepenalty")}</nobr>
                </td>
              )}
              {trahviajad.length > 1 && (
                <td rowSpan={ajarowspan} colSpan={ajacolspan}>
                  <nobr>{t("result.timepenalties")}</nobr>
                </td>
              )}
              {eventData.lisapunktidused && <td rowSpan={3}>{t("result.extrapoints")}</td>}
              {eventData.trahvidused && <td rowSpan={3}>{t("result.extrapenalties")}</td>}
              {Object.entries(eventData.ly || {})
                .filter(
                  ([lyid, ly]) =>
                    ly.forclasses === undefined || ly.forclasses.includes(klass)
                )
                .map(([lyid, ly]) => (
                  <td rowSpan={3} key={lyid}>
                    {ly.name} 
                  </td>
                ))}
              {haveSpdTicket && (
                <td rowSpan={3} style={{whiteSpace: "pre-line"}}>
                  {t("result.speedpenalty")}
                </td>
              )}
              {this.state.havepospenaltys && (
                <td rowSpan={3} style={{whiteSpace: "pre-line"}}>
                  {t("result.pospenalty")}
                </td>
              )}
              <td rowSpan={3}>
                <nobr>{t("result.time")}</nobr>
              </td>
              {eventData.distanceinresult && (<><td rowSpan={3}>{t("result.distance")}</td>
              {Boolean(eventData.dstresult?.targetdst) && <td rowSpan={3}>{t("result.distancepoints")}</td>}</>)}
              <td rowSpan={3}>{t("result.total")}</td>
            </tr>
            <tr>
              <td colSpan={eventData.rakoef.filter((e) => e).length}>
                {t("result.difficulties")}
              </td>
              {eventData.firstkpvisitbonus && <td rowSpan={2}>Esimene</td>}
              <td rowSpan={2} style={{ whiteSpace: "pre-line" }}>
                {questionsresult ? t("result.rightcount"): t("result.kpcount")}
              </td>
              {!hidewrongmarks && (
                <td rowSpan={2} style={{ whiteSpace: "pre-line" }}>
                  {questionsresult ? t("result.wrongcount"): t("result.wrongmarks")}
                </td>
              )}
              <td rowSpan={2}>{t("result.kppenaltys")}</td>
              <td rowSpan={2}>{t("result.kptotal")}</td>
              {trahviajad.length > 1 && (
                <td rowSpan={1} colSpan={trahviajad.length}>
                  {t("result.extratimes")}
                </td>
              )}
              {trahviajad.length > 1 && <td rowSpan={2}>{t("result.timestotal")}</td>}
            </tr>
            <tr>
              {[...eventData.rakoef.keys()]
                .filter((i) => eventData.rakoef[i])
                .map((ra) => (
                  <td key={ra}>{ra}</td>
                ))}
              {trahviajad.length > 1 &&
                trahviajad.map((ta, idx) => (
                  <td key={idx}>{trahviajanumber++}</td>
                ))}
            </tr>
          </thead>
            {this.state.resultorder.map((e, idx) => (
              <ValikTeamResult
                onClick={
                  allowTeamClick ? () => this.props.setViewTeamResult(e) : null
                }
                key={e}
                koht={idx + 1}
                klass={klass}
                tid={e}
                havekiirustrahvid={haveSpdTicket}
                havepospenaltys={this.state.havepospenaltys}
                pointssuffix={eventData.pointssuffix}
                newResult={this.setTeamResult(e)}
              />
            ))}
        </table>
      </Paper>
    );
  }
}
/* FlipMove parameters playground : http://joshwcomeau.github.io/react-flip-move/examples/#/laboratory?_k=3vqm37 */

const createDeepEqualSelector = createSelectorCreator(lruMemoize, isEqual);

const makeGetClassTeams = () => {
  return createDeepEqualSelector(
    [teamsList => {
      return teamsList;
    }, (_, klass) => {
      return klass;
    }],
    (teamsList, klass) => {
      return Object.entries(teamsList)
        .filter(([_, t]) => {
          return !t.disabled && (klass === undefined || (t.klassid || {})[klass]);
        })
        .map(([tid, t]) => {
          return Object.assign({}, t, { id: tid });
        });
    }
  );
};

const makeGetHaveSpdTicket = () => {
  return createSelector([(state) => state.spdTickets], (t) => {
    if (!t) return null;
    return (
      Object.values(t).find((teamtickets) =>
        Object.values(teamtickets).find((devtickets) =>
          Object.values(devtickets).find((ticket) => ticket.state === "enabled")
        )
      ) !== undefined
    );
  });
};

const makeGetOrderRules = () => {
  return createSelector(
    (state) => state.event.orderrules?.split(",") || defaultorderrules,
    (rules) => {
      return rules.map((r) => allorderrules[r]);
    }
  );
};

const makeMapStateToProps = () => {
  const getClassTeams = makeGetClassTeams();
  const getHaveSpdTicket = makeGetHaveSpdTicket();
  const getOrderRules = makeGetOrderRules();
  const mapStateToProps = (state, props) => {
    return {
      haveSpdTicket: getHaveSpdTicket(state),
      eventData: state.event,
      eventAccess: Boolean(state.user.eventAccess),
      teamsList: getClassTeams(state.teamsList, props.klass),
      orderrules: getOrderRules(state),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setViewTeamResult: (tid) => dispatch(setViewTeamResult(tid)),
});

ValikEventResult.propTypes = {
  klass: PropTypes.string,
};


export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(withTranslation()(ValikEventResult));
