import { CheckCircleOutline } from "@mui/icons-material";
import { Button, DialogActions, DialogContent, Icon } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { GridRowSelectionModel } from "@mui/x-data-grid/models/gridRowSelectionModel";
import {
  child,
  DatabaseReference,
  remove,
  set,
  push,
  get,
} from "firebase/database";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNL } from "utils/NLContext";
import { CupClassResultType, CupTeamsType } from "./CupResult";
import { green } from "@mui/material/colors";

const CupJoinTeamsDialogContent: React.FC<{
  teamids: GridRowSelectionModel;
  result: CupClassResultType;
  teams: CupTeamsType;
  evids: string[];
  klassresultref: DatabaseReference;
  onClose: (cancel: boolean) => void;
}> = ({ teamids, result, teams, evids, klassresultref, onClose }) => {
  const { t } = useTranslation();
  const { addUndo } = useNL();
  const [keeptid, setKeeptid] = useState(() => {
    let ktid = teamids[0];
    evids.forEach((eid) => {
      teamids.forEach((tid) => {
        if (result[eid] && result[eid][tid]) ktid = tid;
      });
    });
    return ktid;
  });
  const data = teamids.map((tid) => {
    return { id: tid, name: teams[tid].name };
  });
  const columns: GridColDef[] = [
    {
      field: "keep",
      headerName: "keep team",
      width: 100,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (param) => {
        if (keeptid === param.id) {
          return (
            <Icon style={{ color: green[500] }}>
              <CheckCircleOutline />
            </Icon>
          );
        }
        return null;
      },
    },
    {
      field: "name",
      headerName: t("cup.columns.team"),
      flex: 1.2,
      sortable: false,
      headerAlign: "center",
    },
    ...evids.map((evid, idx) => {
      return {
        field: "ev" + evid,
        headerName: `${idx + 1}.`,
        flex: 0.5,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (param) => {
          const evdata = result[evid];
          return evdata && evdata[param.id];
        },
      } as GridColDef; // I get an error without this as for headerAlign
    }),
  ];
  return (
    <>
      <DialogContent>
        <div style={{ height: 400, width: "100%", padding: "20px" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                rows={data}
                columns={columns}
                disableRowSelectionOnClick
                editMode="cell"
                hideFooter
                disableColumnSelector
                onCellClick={(params, event) => {
                  event.stopPropagation();
                  if (params.field === "keep") setKeeptid(params.id as string);
                  console.log("clicked cell: ", params);
                }}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(true)}>{t("button.cancel")}</Button>
        <Button
          onClick={async () => {
            const movetid = teamids.find((tid) => tid !== keeptid)!;
            console.log(
              "Move ",
              movetid,
              "in klasss",
              klassresultref.toJSON(),
              "to team",
              keeptid
            );
            const cupref = klassresultref.parent?.parent;
            if (!cupref) {
              console.error("Bad klassresultref");
              return;
            }
            const oldresult = await get(child(cupref, "result"));
            const oldteams = await get(child(cupref, "teams"));

            const backupref = await push(child(cupref, "backup"));
            await set(child(backupref, "result"), oldresult.val());
            await set(child(backupref, "teams"), oldteams.val());

            evids.forEach((evid) => {
              if (result[evid] && result[evid][movetid]) {
                set(
                  child(klassresultref, `${evid}/${keeptid}`),
                  result[evid][movetid]
                );
                remove(child(klassresultref, `${evid}/${movetid}`));
              }
            });
            const moveteamdata = await get(child(cupref, "teams/" + movetid));
            await set(
              child(cupref, "teams/" + keeptid + "/joinedteams/" + movetid),
              moveteamdata.val()
            );

            addUndo("jointo" + keeptid + movetid, "Joined teams", async () => {
              await set(child(cupref, "result"), oldresult.val());
              await set(child(cupref, "teams"), oldteams.val());
            });
            onClose(false);
          }}
        >
          {t("button.ok")}
        </Button>
      </DialogActions>
    </>
  );
};

export default CupJoinTeamsDialogContent;
