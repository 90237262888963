import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import FBNLTimeField from "components/FBNLTimeField";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "rx/store";
import { Check, Delete, Edit, Warning } from "@mui/icons-material";
import { useNL } from "utils/NLContext";

const classes = {
  smallNrEdit: "centeredTextfield",
  paddedEdit: "paddedEdit",
};

type AjadPerClassesType = {
  [klass: string]: [
    {
      order?: number;
      penalty?: number;
      per?: number;
      time?: number;
    }
  ];
};

const EventSettingsTimes: React.FC<{ eventId: string }> = ({ eventId }) => {
  const ajadfb = useSelector((state: RootState) => state.event.ajad);
  const { fbSet } = useNL();
  const { t } = useTranslation();
  const [editlisaaeg, setEditLisaaeg] = useState(
    undefined as number | undefined
  );
  const [editlatime, setEditLaTime] = useState("");
  const [editlapenalty, setEditLaPenalty] = useState("");
  const [editlaper, setEditLaPer] = useState("");

  const lisaajad = ajadfb?.lisaajad?.filter((e) => e) || [];
  const evdata = `/eventsdata/${eventId}/data/`;

  const ajadperklasses = ajadfb as AjadPerClassesType;
  const hasklasstimes = Object.entries(ajadperklasses || {}).find(
    ([key, ent]) => Array.isArray(ent) && ent.length > 0 && key !== "lisaajad"
  );
  return (
    <Grid container spacing={2}>
      {hasklasstimes !== undefined && (
        <Grid size={12}>
          <Warning color="secondary" /> {t("valiktimes.warningofoldtimes")}
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              const klassid = Object.keys(ajadperklasses);
              if (klassid.length > 0) {
                let lisanum = 0;
                ajadperklasses[klassid[0]]
                  .slice()
                  .sort((a, b) => {
                    console.log("a, b", a, b, (a.order || 0) - (b.order || 0));
                    return (a.order || 0) - (b.order || 0);
                  })
                  .forEach((ent) => {
                    console.log(ent);
                    if (ent.penalty === 0 && ent.per === 0) {
                      fbSet(evdata + "ajad/normaalaeg", ent.time);
                    } else {
                      fbSet(evdata + "ajad/lisaajad/" + lisanum++, {
                        time: ent.time,
                        per: ent.per,
                        penalty: ent.penalty,
                      });
                    }
                  });
                klassid.forEach((kl) => {
                  if (kl === "lisaajad" || kl === "normaalaeg") return;
                  fbSet(evdata + "ajad/" + kl, null);
                });
              }
            }}
          >
            {t("valiktimes.convertold")}
          </Button>
        </Grid>
      )}
      <Grid size={12}>
        <Grid container alignItems="center" spacing={1}>
          <Grid>
            <Typography variant="h6">
              {t("valiktimes.normaltimeprefix")} :{" "}
            </Typography>
          </Grid>
          <Grid>
            <FBNLTimeField
              id="normaalaeg"
              path={evdata + "ajad/normaalaeg"}
              style={{ width: "8ch" }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid size={12}>
        <Divider />
      </Grid>
      <Grid>
        <Typography variant="h6">Lisaajad</Typography>
        <List dense>
          {lisaajad.map((it, idx) => {
            return editlisaaeg === idx ? (
              <ListItem
                sx={{
                  [`& .${classes.smallNrEdit}`]: {
                    width: "5ch",
                    textAlign: "center",
                    "&[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                    "&::-webkit-outer-spin-button": {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                    "&::-webkit-inner-spin-button": {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                  },
                  [`& .${classes.paddedEdit}`]: {
                    marginLeft: 1,
                    marginRight: 1,
                  },
                }}
              >
                {idx + 1}.{t("valiktimes.lisaaaeg")}
                <TextField
                  variant="standard"
                  className={classes.paddedEdit}
                  type="number"
                  value={editlatime}
                  onChange={(ev) => setEditLaTime(ev.target.value)}
                  slotProps={{
                    input: {
                      inputProps: { className: classes.smallNrEdit },
                    },
                  }}
                />
                {t("valiktimes.minutes", {
                  count: Number(editlatime),
                })}
                {". "}
                {t("valiktimes.trahv")}
                <TextField
                  variant="standard"
                  className={classes.paddedEdit}
                  type="number"
                  value={editlapenalty}
                  onChange={(ev) => setEditLaPenalty(ev.target.value)}
                  slotProps={{
                    input: {
                      inputProps: { className: classes.smallNrEdit },
                    },
                  }}
                />
                {t("valiktimes.points", {
                  count: Number(editlapenalty),
                }) +
                  " " +
                  t("valiktimes.per")}
                <TextField
                  variant="standard"
                  className={classes.paddedEdit}
                  type="number"
                  value={editlaper}
                  onChange={(ev) => setEditLaPer(ev.target.value)}
                  slotProps={{
                    input: {
                      inputProps: { className: classes.smallNrEdit },
                    },
                  }}
                />
                {t("valiktimes.minutes", {
                  count: Number(editlaper),
                })}
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => {
                      fbSet(
                        evdata + "ajad/lisaajad/" + editlisaaeg,
                        {
                          time: Number(editlatime) * 60,
                          per: Number(editlaper) * 60,
                          penalty: Number(editlapenalty),
                        },
                        t("valiktimes.lisaaegchanged")
                      );
                      setEditLisaaeg(undefined);
                    }}
                    size="large"
                  >
                    <Check />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ) : (
              <ListItem>
                <ListItemAvatar>
                  <IconButton
                    onClick={() => {
                      setEditLisaaeg(idx);
                      setEditLaTime(((it.time || 0) / 60).toString());
                      setEditLaPenalty((it.penalty || 1).toString());
                      setEditLaPer(((it.per || 0) / 60).toString());
                    }}
                    size="large"
                  >
                    <Edit />
                  </IconButton>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    idx +
                    1 +
                    ". " +
                    t("valiktimes.lisaaeginminutes", {
                      count: (it.time || 0) / 60,
                    }) +
                    " - " +
                    t("valiktimes.penaltys", {
                      count: it.penalty,
                    }) +
                    " " +
                    t("valiktimes.per") +
                    " " +
                    t("valiktimes.perinminutes", {
                      count: (it.per || 0) / 60,
                    })
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => {
                      let newajad = [...lisaajad];
                      newajad.splice(idx, 1);
                      fbSet(
                        evdata + "ajad/lisaajad/",
                        newajad,
                        t("valiktimes.lisaaegremoved")
                      );
                    }}
                    size="large"
                  >
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </Grid>
      <Grid></Grid>
      <Grid size={12}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            const timenum = lisaajad.length;
            let defper = 60;
            if (timenum === 0) defper = 180;
            else if (timenum === 1) defper = 120;
            fbSet(evdata + "/ajad/lisaajad/" + timenum.toString(), {
              penalty: 1,
              per: defper,
              time: 1800,
            });
          }}
        >
          {t("button.addlisaaeg")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default EventSettingsTimes;
