import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  MenuItem,
  Grid,
} from "@mui/material";
import { withTranslation, WithTranslation } from "react-i18next";
import { setEditTeamKP } from "rx/appStateSlice";
import FBKeyboardDateTimePicker from "./FBKeyboardDateTimePicker";
import FBTextField from "./FBTextField";

const mapState = (state: RootState, props: EditTeamKPOwnProps) => ({
  teamname: state.teamsList[props.tid]?.name,
  inv: state.teamsList,
  kp: state.kpList[props.kpid],
  kpData: state.kpData[props.kpid],
});
const mapDispatch = {
  closeDialog: () => setEditTeamKP(),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type EditTeamKPOwnProps = {
  eventId: string;
  tid: string;
  kpid: string;
};
type EditTeamKPProps = PropsFromRedux & WithTranslation & EditTeamKPOwnProps;
type EditTeamKPState = {};
class EditTeamKP extends React.Component<EditTeamKPProps, EditTeamKPState> {
  state: EditTeamKPState = {};

  render() {
    const { t, teamname, kp, kpData, tid, kpid, eventId } = this.props;
    return (
      <Dialog open={true}>
        <DialogTitle>
          {t("editteamkp.title", { team: teamname, kpnr: kp.nr })}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid size={12}>
              <FBKeyboardDateTimePicker
                ampm={false}
                views={["hours", "minutes", "seconds"] as any}
                format="DD.MMM HH:mm:ss"
                id="answertime"
                path={`/teams/${eventId}/kpanswers/${tid}/${kpid}/aeg`}
              />
            </Grid>
            <Grid size={12}>
              <FBTextField
                fullWidth
                path={`/teams/${eventId}/kpanswers/${tid}/${kpid}/answer`}
                select
                label={t("teamkp.answer.label")}
                margin="normal"
                variant="outlined"
              >
                {Object.entries(kpData?.responses || {}).map(([k, value]) => (
                  <MenuItem key={k} value={k}>
                    {value}
                  </MenuItem>
                ))}
              </FBTextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeDialog} variant="contained">
            {t("button.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connector(withTranslation()(EditTeamKP));
