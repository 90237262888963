import { Box, Grid, InputAdornment, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "rx/store";
import FBSwitch from "./FBSwitch";
import FBTextField from "./FBTextField";
import FSTextField from "./FSTextField";

const classes = {
  inLineTextField: "inLineTextField",
  centeredTextfield: "centeredTextfield",
};

const PenaltyLine: React.FC<{
  evdoc: string;
  num: "1" | "2";
}> = ({ evdoc, num }) => {
  const speed = useSelector((state: RootState) => state.eventDoc?.speed);
  const { t } = useTranslation();
  const ovkey = num === "1" ? "over1" : "over2";
  const delaykey = num === "1" ? "delay1" : "delay2";

  return (
    <Grid size={12}>
      <Box display="flex" alignItems="baseline">
        <Typography variant="h6" style={{ marginRight: "20px" }}>
          {t("spdpenalty.title")}
          {num === "1" ? " I :" : " II :"}
        </Typography>
        <Typography variant="subtitle1"> {t("spdpenalty.prefix")} </Typography>
        <FSTextField
          id={"spdlim.over"}
          type="number"
          path={evdoc}
          field={"speed.over" + num}
          className={classes.inLineTextField}
          InputProps={{
            inputProps: { className: classes.centeredTextfield },
            endAdornment: <InputAdornment position="end">km/h</InputAdornment>,
          }}
          style={{ width: "8ch", marginTop: 0 }}
          margin="normal"
          variant="standard"
        />
        {speed && speed.limit && speed[ovkey] && (
          <Typography variant="subtitle1">
            (&gt;={" "}
            {Number(speed?.limit || 0) + Number(speed ? speed[ovkey] : 0)} km/h{" "}
            {t("spdpenalty.genarea")}){" "}
          </Typography>
        )}
        <FSTextField
          id={"spdlim.delay"}
          path={evdoc}
          field={"speed.delay" + num}
          type="number"
          className={classes.inLineTextField}
          InputProps={{
            inputProps: { className: classes.centeredTextfield },
          }}
          style={{ width: "4ch" }}
          margin="normal"
          variant="standard"
        />
        <Typography variant="subtitle1">
          {t("spdpenalty.times", { count: speed ? speed[delaykey] : 0 })}{" "}
        </Typography>
      </Box>
    </Grid>
  );
};

const EventSettingsSpeed: React.FC = (props) => {
  const eventId = useSelector((state: RootState) => state.eventId);

  const evdata = `/eventsdata/${eventId}/data/`;
  const evdoc = `/events/${eventId}`;
  return (
    <Grid
      container
      spacing={2}
      justifyContent="flex-start"
      sx={{
        [`& .${classes.inLineTextField}`]: {
          marginLeft: 1,
          marginRight: 1,
          marginTop: 0,
          marginBottom: 0,
        },
        [`& .${classes.centeredTextfield}`]: {
          textAlign: "center",
          "&[type=number]": {
            MozAppearance: "textfield",
          },
          "&::-webkit-outer-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
          "&::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
        },
      }}
    >
      <Grid size={{ sm: 12 }}>
        <FBSwitch id="hidespeedings" path={`${evdata}hidespeedings`} />
      </Grid>
      <Grid size={{ xs: 12, sm: "auto" }}>
        <FSTextField
          id="limit"
          path={evdoc}
          field="speed.limit"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">km/h</InputAdornment>,
          }}
          margin="normal"
          variant="outlined"
          style={{ width: "20ch" }}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 8 }}>
        <FBTextField
          id="speedpenalty"
          path={evdata + "/speedpenalty"}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          variant="outlined"
        />
      </Grid>
      <PenaltyLine evdoc={evdoc} num="1" />
      <PenaltyLine evdoc={evdoc} num="2" />
    </Grid>
  );
};

export default EventSettingsSpeed;
