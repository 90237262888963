import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { FieldSelectedSections } from "@mui/x-date-pickers";
import dayjs, { duration } from "dayjs";
import { child, DatabaseReference } from "firebase/database";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RootState, useTSelector } from "rx/store";
import { useNL } from "utils/NLContext";
import { formatDuration } from "utils/SmallUtils";
import FBKeyboardDateTimePicker from "./FBKeyboardDateTimePicker";

type AjadType = RootState["event"]["ajad"];

const calcTimeLeftValue = (ajad: AjadType, runtime: number) => {
  let totaltime = 0;
  if (!ajad) return totaltime;
  if (ajad.normaalaeg) {
    totaltime += ajad.normaalaeg;
    if (ajad.lisaajad) {
      const lisaajad = Array.isArray(ajad.lisaajad)
        ? ajad.lisaajad
        : (Object.values(ajad.lisaajad) as NonNullable<
          NonNullable<AjadType>["lisaajad"]
        >);

      totaltime += lisaajad.reduce((acc, cv) => acc + (cv.time || 0), 0);
    }
  }
  const timeleft = totaltime * 1000 - runtime;
  return timeleft > 0 ? timeleft : 0;
};

const RunningTime: React.FC<{ starttime: number }> = (props) => {
  const [showTimeLeft, setShowTimeLeft] = useState(false);
  const ajad = useTSelector((state) => state.event.ajad);
  const [runTime, setRunTime] = useState(Date.now() - props.starttime);
  useEffect(() => {
    const timer = setInterval(() => {
      setRunTime(Date.now() - props.starttime);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [props.starttime]);

  const { t } = useTranslation();
  const time = showTimeLeft ? calcTimeLeftValue(ajad, runTime) : runTime;

  const secondary = time > 86400000 ? formatDuration(duration(time)) : dayjs.utc(time).format("HH:mm:ss");

  return (
    <ListItemText
      onClick={() => {
        setShowTimeLeft(!showTimeLeft);
      }}
      primary={
        showTimeLeft ? t("team.timeleft.label") : t("team.timesincestart.label")
      }
      primaryTypographyProps={{ noWrap: true }}
      secondary={secondary}
    />
  );
};

const StartFinishResultCard: React.FC<{
  tid: string;
  teamref: DatabaseReference;
}> = ({ tid, teamref }) => {
  const team = useTSelector((state) => state.teamsList[tid]);
  const { archived, isAdmin } = useTSelector(
    (state) => {
      return {
        archived: Boolean(state.event.arch),
        isAdmin: Boolean(state.user.eventAccess),
      };
    },
    (a, b) => {
      return a.archived === b.archived || a.isAdmin === b.isAdmin;
    }
  );
  const [initialFinishOpen, setInitialFinishOpen] = useState(false);
  const [finishSelectedSection, setFinishSelectedSection] =
    useState<FieldSelectedSections>(null);

  const { fbSet } = useNL();
  const { t } = useTranslation();

  if (!team) return null;
  return (
    <Card>
      <Grid container spacing={2} sx={{ padding: 2 }} alignItems="center">
        <Grid>
          <FBKeyboardDateTimePicker
            label={t("team.starttime.label") + "abc"}
            path={child(teamref, "starttime")}
            id="teamstarttime"
            disableTooltip
            format="HH:mm:ss"
            slotProps={{
              actionBar: {
                actions: ["cancel", "clear", "today"],
              },
            }}
          />
        </Grid>
        {team.finishtime && (
          <Grid>
            <FBKeyboardDateTimePicker
              label={t("team.finishtime.label")}
              path={child(teamref, "finishtime")}
              id="teamstarttime"
              disableTooltip
              format="HH:mm:ss"
              autoFocus={initialFinishOpen}
              selectedSections={finishSelectedSection}
              onSelectedSectionsChange={(s) => {
                if (initialFinishOpen) {
                  setInitialFinishOpen(false);
                  return;
                }
                setFinishSelectedSection(s);
              }}
              minDateTime={dayjs(team.starttime)}
              slotProps={{
                actionBar: {
                  actions: ["cancel", "clear", "today"],
                },
              }}
            />
          </Grid>
        )}

        {team.starttime && !team.finishtime && (
          <Grid>
            <ListItem>
              <RunningTime starttime={team.starttime} />
            </ListItem>
          </Grid>
        )}
        {isAdmin && !archived && !team.finishtime && (
          <Grid>
            <Button
              variant="contained"
              onClick={() => {
                setFinishSelectedSection(1);
                setInitialFinishOpen(true);
                fbSet(
                  child(teamref, "finishtime"),
                  Date.now(),
                  t("team.finishtime.set")
                );
              }}
            >
              {t("team.finishbutton")}
            </Button>
          </Grid>
        )}
        {team.starttime && team.finishtime && (
          <Grid>
            <ListItemText
              primary={t("team.finaltime.primary")}
              secondary={formatDuration(
                dayjs.duration(team.finishtime - team.starttime)
              )}
            />
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default StartFinishResultCard;
