import { Button } from "@mui/material";
import Grid2 from "@mui/material/Grid";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openDialog } from "rx/dialogsSlice";
import { useTSelector } from "rx/store";
import { useRFirebaseDb } from "utils/FirebaseDbWrapper";
import { useNL } from "utils/NLContext";

const SignInGridItem: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Grid2 display="flex" justifyContent="center" size={{ xs: 12 }}>
      <Button
        variant="contained"
        onClick={() => {
          dispatch(openDialog("signin"));
        }}
      >
        {t("button.signin")}
      </Button>
    </Grid2>
  );
};

const DeleteAccountGridItem: React.FC = () => {
  const user = useTSelector((state) => state.user.user);
  const fbpath = `/accountdeleterequests/${user?.uid}`;
  const requested = useRFirebaseDb<number>(fbpath);
  const { fbSet } = useNL();
  if (!user) return null;
  if (requested) {
    return (
      <Grid2 display="flex" justifyContent="center" size={{ xs: 12 }}>
        Account deleteion already requested. (
        {user?.displayName || user?.email || user?.phoneNumber})
      </Grid2>
    );
  }
  return (
    <Grid2 display="flex" justifyContent="center" size={{ xs: 12 }}>
      <Button
        variant="contained"
        onClick={() => {
          fbSet("/accountdeleterequests/" + user.uid, new Date().getTime());
        }}
      >
        Please delete my account (
        {user?.displayName || user?.email || user?.phoneNumber})
      </Button>
    </Grid2>
  );
};
const AccountDelete: React.FC = () => {
  const user = useTSelector((state) => state.user.user);

  return (
    <Grid2 container>
      {user ? <DeleteAccountGridItem /> : <SignInGridItem />}
    </Grid2>
  );
};

export default AccountDelete;
