import { LatLngBounds } from "leaflet";
import React, { useCallback, useMemo, useState } from "react";
import { Rectangle, useMap, useMapEvent } from "react-leaflet";
import NutiMap from "./NutiMap";

function useEventHandlers(element: any, eventHandlers: any) {
  const eventHandlersRef = React.useRef<any>(null);
  React.useEffect(
    function addEventHandlers() {
      if (eventHandlers != null) {
        element.instance.on(eventHandlers);
      }

      eventHandlersRef.current = eventHandlers;
      return function removeEventHandlers() {
        if (eventHandlersRef.current != null) {
          element.instance.off(eventHandlersRef.current);
        }

        eventHandlersRef.current = null;
      };
    },
    [element, eventHandlers]
  );
}

const MinimapBounds: React.FC<{
  parentMap: L.Map;
  zoom: number;
  evbounds: LatLngBounds;
}> = ({ parentMap, zoom, evbounds }) => {
  const minimap = useMap();
  useMemo(() => {
    minimap.fitBounds(evbounds);
  }, [evbounds, minimap]);
  const onClick = useCallback(
    (e: any) => {
      parentMap.setView(e.latlng, parentMap.getZoom());
    },
    [parentMap]
  );
  useMapEvent("click", onClick);

  const [bounds, setBounds] = useState(parentMap.getBounds());
  const onChange = useCallback(() => {
    setBounds(parentMap.getBounds());
  }, [parentMap]);

  const handlers = useMemo(
    () => ({ move: onChange, zoom: onChange }),
    [onChange]
  );
  useEventHandlers({ instance: parentMap }, handlers);

  const BOUNDS_STYLE = { weight: 1 };
  return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />;
};

const MinimapControl: React.FC<{ evbounds: LatLngBounds }> = ({ evbounds }) => {
  const parentMap = useMap();
  const mapZoom = 0;

  const minimap = useMemo(
    () => (
      <NutiMap
        style={{ height: 80, width: 80 }}
        center={parentMap.getCenter()}
        zoom={mapZoom}
        dragging={false}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        attributionControl={false}
        zoomControl={false}
        disableLayersControl
      >
        <MinimapBounds
          parentMap={parentMap}
          zoom={mapZoom}
          evbounds={evbounds}
        />
      </NutiMap>
    ),
    [evbounds, parentMap]
  );
  /*
const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
};
*/

  return (
    <div className={"leaflet-top leaflet-right"}>
      <div className="leaflet-control leaflet-bar">{minimap}</div>
    </div>
  );
};

export default MinimapControl;
