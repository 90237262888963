import React, { useCallback } from "react";
import { RootState, useTSelector } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Stack,
  Switch,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  useTranslation,
  withTranslation,
  WithTranslation,
} from "react-i18next";
import { setEditKPId, setRichEditPath } from "rx/appStateSlice";
import NutiMap from "utils/NutiMap";
import FBTextField from "./FBTextField";
import KPMarker from "utils/KPMarker2";
import { isMobile } from "react-device-detect";
import { withNL, WithNLProps } from "utils/NLContext";
import FBSwitch from "./FBSwitch";
import {
  AddCircle,
  CheckCircleTwoTone,
  Close,
  Delete,
  Done,
  Edit,
} from "@mui/icons-material";
import SingleKPStatistics from "./SingleKPStatistics";
import LineKP from "./LineKP";
import FBLatLng from "./FBLatLng";
import { child, getDatabase, push, ref } from "firebase/database";
import { FlyToLoc } from "utils/MapUtils";
import { useDispatch } from "react-redux";
import { kpnrcompare } from "./KPData";

const DependKPCombo: React.FC<{
  eventId: string;
  kpId: string;
  dependkp?: string;
  archived: boolean;
}> = ({ eventId, kpId, dependkp, archived }) => {
  const { t } = useTranslation();
  const kplist = useTSelector((s) => s.kpList);
  return (
    <FBTextField
      path={`/eventsdata/${eventId}/kp/${kpId}/dependkp`}
      tooltipfollow
      id="dependkp"
      select
      label={t("kp.dependkp.label")}
      value={dependkp ?? ""}
      margin="normal"
      variant="outlined"
      style={{ width: "10ch" }}
      inputProps={{
        readOnly: archived,
      }}
    >
      <MenuItem key="none" value="">
        --
      </MenuItem>
      {Object.keys(kplist || {}).map((r) => (
        <MenuItem key={r} value={r}>
          KP {kplist[r].nr}
        </MenuItem>
      ))}
    </FBTextField>
  );
};

type KPValueType = "correct" | "incorrect" | "unmarked";
const useKPValue = (kpId: string, name: KPValueType) => {
  const kp = useTSelector(s => s.kpList[kpId]);
  const event = useTSelector(s => s.event);
  const value = (event?.rakoef && event.rakoef[kp?.ra ?? 1]) ?? 0;
  if (name === "correct") return value
  if (name === "unmarked") {
    if (kp.kohustuslik) return -value * 2;
    return 0;
  }
  let incorrectvalue = kp.wrongpenalty ?? (event.wrongpenaltyenabled ? (event.wrongcoef ?? 1) * value : 0);
  if (event.answerscores) incorrectvalue -= value;
  if (name === "incorrect") return -incorrectvalue;
  return 0;
}
const ValueTableRow: React.FC<{ name: KPValueType, color: string, kpId: string }> = ({ name, color, kpId }) => {
  const { t } = useTranslation();
  const value = useKPValue(kpId, name);

  return <Tooltip title={t(`kpvalue.tooltip.${name}`)}><TableRow >
    <TableCell sx={{ fontSize: 10, bgcolor: color, padding: '5px' }} >{t(`kpvalue.name.${name}`)}</TableCell>
    <TableCell sx={{ fontSize: 12, fontWeight: "bold", bgcolor: color, padding: '5px' }} align="right">{value}</TableCell>
  </TableRow></Tooltip>
}

const PrevNextKP: React.FC<{ thiskpid: string }> = ({ thiskpid }) => {
  const { t } = useTranslation();
  const kplist = useTSelector((s) => s.kpList);
  const dispatch = useDispatch();
  const sortedkplist = React.useMemo(() => {
    return Object.entries(kplist).sort((l, r) => {
      return kpnrcompare(l[1].nr, r[1].nr);
    });
  }, [kplist]);

  const moveToKp = useCallback(
    (fromkpid: string, sortedlist: typeof sortedkplist, delta: number) => {
      const idx = sortedlist.findIndex((e) => e[0] === fromkpid);
      if (idx === -1 || idx + delta < 0 || idx + delta >= sortedlist.length)
        return fromkpid;
      dispatch(setEditKPId(sortedlist[idx + delta][0]));
    },
    [dispatch]
  );
  return (
    <Stack direction="row" spacing={2}>
      <Button
        aria-label="prev"
        variant="contained"
        onClick={() => moveToKp(thiskpid, sortedkplist, -1)}
        disabled={thiskpid === sortedkplist[0][0]}
      >
        {t("button.prevkp")}
      </Button>
      <Button
        aria-label="next"
        variant="contained"
        onClick={() => moveToKp(thiskpid, sortedkplist, 1)}
        disabled={thiskpid === sortedkplist.slice(-1)[0][0]}
      >
        {t("button.nextkp")}
      </Button>
    </Stack>
  );
};

const ValikVastusEditor: React.FC<{
  value?: string;
  submitValue: (v: string) => void;
  cancelEdit?: () => void;
  inset?: boolean;
  disabled?: boolean;
}> = (props) => {
  const { t } = useTranslation();
  const [values, setValues] = React.useState({
    newresp: props.value || "",
  });

  const submit = () => {
    props.submitValue(values.newresp);
    setValues({ ...values, newresp: "" });
  };
  let arialabel = props.value === undefined ? "Add" : "Done";
  return (
    <ListItemText
      onClick={(e) => {
        e.stopPropagation();
      }}
      inset={props.inset !== undefined ? props.inset : true}
      primary={[
        <TextField
          key="field"
          disabled={props.disabled}
          autoFocus={props.value !== undefined}
          onKeyDown={(e) => {
            if (e.keyCode === 13 && values.newresp.length > 0) submit();
          }}
          value={values.newresp}
          onChange={(event) => {
            setValues({ ...values, newresp: event.target.value });
          }}
          label={props.value ? "" : t("kp.addresponse")}
        />,
        <IconButton
          aria-label={arialabel}
          onClick={submit}
          disabled={props.disabled || values.newresp.length === 0}
          key="okbutton"
          size="large"
        >
          {props.value === undefined ? <AddCircle /> : <Done />}
        </IconButton>,
        props.value !== undefined && (
          <IconButton
            aria-label="Close"
            onClick={props.cancelEdit}
            key="closebutton"
            size="large"
          >
            <Close />
          </IconButton>
        ),
      ]}
    />
  );
};

const ValikVastusEditorListItem: React.FC<{
  addKPResponse: (value: string) => void;
  disabled: boolean;
}> = (props) => {
  const [values, setValues] = React.useState({
    newresp: "",
  });
  const { t } = useTranslation();

  const submit = () => {
    props.addKPResponse(values.newresp);
    setValues({ ...values, newresp: "" });
  };
  return (
    <ListItem>
      <ListItemText
        inset
        primary={
          <TextField
            disabled={props.disabled}
            onKeyDown={(e) => {
              if (e.keyCode === 13) submit();
            }}
            value={values.newresp}
            onChange={(event) => {
              setValues({ ...values, newresp: event.target.value });
            }}
            label={t("label.addanswer")}
          />
        }
      ></ListItemText>
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="Add"
          onClick={submit}
          disabled={props.disabled || values.newresp.length === 0}
          key="Add"
          size="large"
        >
          <AddCircle />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const mapState = (state: RootState, props: EditKPOwnProps) => ({
  hasAccess: state.user.eventAccess,
  kpData: state.kpData[props.kpId] || {},
  archived: Boolean(state.event.arch),
  kp: state.kpList[props.kpId],
  answer: state.kpAnswers[props.kpId],
  rakoef: state.event.rakoef,
  kpgroups: state.event.kpgroups,
  evnokpopenwhennotclose: state.event.nokpopenwhennotclose,
});
const mapDispatch = {
  closeDialog: () => setEditKPId(undefined),
  setRichEditPath: (path: string | undefined) => setRichEditPath(path),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type EditKPOwnProps = {
  eventId: string;
  kpId: string;
};
type EditKPProps = PropsFromRedux &
  WithTranslation &
  WithNLProps &
  EditKPOwnProps;
type EditKPState = {
  editrespid?: string;
  turntolinekp: boolean;
};
class EditKP extends React.Component<EditKPProps, EditKPState> {
  state: EditKPState = {
    turntolinekp: false,
  };
  componentDidUpdate(prevProps: EditKPProps) {
    /*
    if (!prevProps.kpData?.loc && this.props.kpData?.loc) {
      if (!this.map?.getBounds().contains(this.props.kpData.loc))
        this.map?.flyTo(this.props.kpData.loc, 15);
    }
    */
  }

  rmKPResponse = (respid: string, response: string) => {
    // TODO translation
    const { eventId, kpId, answer, fbSet, fbRemove, addUndo } = this.props;
    let evref = ref(getDatabase(), `/eventsdata/${eventId}`);
    let responsesref = child(evref, `kpdata/${kpId}/responses/${respid}`);
    const answerref = child(evref, `kpanswer/${kpId}`);
    fbRemove(responsesref);
    const wascorrectanswer = answer === respid;
    if (wascorrectanswer) {
      let ent = Object.entries(this.props.kpData?.responses || {}).find(
        ([k, _]) => k !== respid
      );
      if (ent !== undefined) fbSet(answerref, ent[0]);
      else fbRemove(answerref);
    }
    addUndo(
      kpId + "rmkpresponse",
      "Removed " + response + " as a response from KP",
      () => {
        fbSet(responsesref, response);
        if (wascorrectanswer) fbSet(answerref, respid);
      }
    );
  };
  updateKPResponse = (respid: string, value: string) => {
    const { eventId, kpId, t, fbSet } = this.props;
    fbSet(
      `/eventsdata/${eventId}/kpdata/${kpId}/responses/${respid}`,
      value,
      t("kp.updaterespons.undomsg")
    );

    this.setState({ editrespid: undefined });
    console.log("updated");
  };

  addKPResponse = (value: string) => {
    // TODO translation
    const { kpData, kpId, fbSet, fbRemove } = this.props;
    const firstresponse = kpData?.responses === undefined;
    let evref = ref(getDatabase(), `/eventsdata/${this.props.eventId}`);
    let newref = push(child(evref, `kpdata/${kpId}/responses`));
    if (firstresponse) {
      fbSet(child(evref, `kpanswer/${kpId}`), newref.key);
    }
    fbSet(newref, value);

    this.props.addUndo(
      kpId + "addresponse",
      "Added " + value + " as a response to KP",
      () => {
        fbRemove(newref);
        if (firstresponse) fbRemove(child(evref, `kpanswer/${kpId}`));
      }
    );
  };
  render() {
    const {
      t,
      eventId,
      kpId,
      kpData,
      kp,
      answer,
      rakoef,
      kpgroups,
      fbSet,
      archived,
      evnokpopenwhennotclose,
    } = this.props;
    if (!this.props.hasAccess) return null;
    const responses = kpData.responses;
    if (!kp) {
      return null;
    }
    const asLineKP = this.state.turntolinekp || kpData.a;
    return (
      <Dialog open={true} maxWidth="lg" fullWidth fullScreen={isMobile}>
        <DialogTitle>
          <Grid container>
            <Grid>
              {kp.nr === "F"
                ? t("editkp.finishtitle")
                : t("editkp.title", { nr: kp.nr })}
            </Grid>
            <Grid ml="auto" alignItems="center">
              <PrevNextKP thiskpid={kpId} />
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <div
            style={{
              height: "40vh",
              width: "100%",
              paddingTop: 10,
            }}
          >
            <NutiMap
              editable
              editOptions={{ skipMiddleMarkers: true }}
              onClick={
                kpData?.loc || asLineKP
                  ? undefined
                  : (ll) => {
                      this.props.fbSet(
                        `/eventsdata/${eventId}/kpdata/${kpId}/loc`,
                        ll,
                        t("kp.locadded")
                      );
                    }
              }
            >
              {kpData.loc && kpData.loc.lat && kpData.loc.lng && (
                <FlyToLoc loc={kpData.loc} zoom={15} />
              )}
              {asLineKP && (
                <LineKP
                  kp={kpData}
                  kpdatapath={`/eventsdata/${eventId}/kpdata/${kpId}`}
                />
              )}
              {!asLineKP && kpData.loc && (
                <KPMarker
                  eventHandlers={{
                    dragend: (ll) => {
                      this.props.fbSet(
                        `/eventsdata/${eventId}/kpdata/${kpId}/loc`,
                        ll.target.getLatLng(),
                        t("kp.moved")
                      );
                      console.log("drag end", ll.target.getLatLng());
                    },
                  }}
                  draggable={!archived}
                  kp={kp}
                  kpdata={kpData}
                />
              )}
            </NutiMap>
          </div>
          <Grid container spacing={2} paddingTop={2} alignItems="center">
            {kp?.nr !== "F" && (
              <Grid>
                <FBTextField
                  id="nr"
                  style={{ width: "10ch" }}
                  path={`/eventsdata/${eventId}/kp/${kpId}/nr`}
                  value={archived ? kp.nr : undefined}
                  margin="normal"
                  variant="outlined"
                  inputProps={{
                    style: { textAlign: "center" },
                    readOnly: archived,
                  }}
                />
              </Grid>
            )}
            {!kp?.allok && !asLineKP && (
              <>
                <Grid>
                  {responses ? (
                    <FBTextField
                      path={`/eventsdata/${eventId}/kpanswer/${kpId}`}
                      select
                      id="answer"
                      label={t("kp.answer.label")}
                      margin="normal"
                      variant="outlined"
                      value={archived ? answer : undefined}
                      inputProps={{
                        readOnly: archived,
                      }}
                    >
                      {Object.entries(responses).map(([k, value]) => (
                        <MenuItem key={k} value={k}>
                          {value}
                        </MenuItem>
                      ))}
                    </FBTextField>
                  ) : (
                    <FBTextField
                      path={`/eventsdata/${eventId}/kpanswer/${kpId}`}
                      disabled={Boolean(responses) || kp?.allok}
                      id="tahis"
                      label="Tähis"
                      value={archived ? answer : undefined}
                      inputProps={{
                        style: { textAlign: "center" },
                        readOnly: archived,
                      }}
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                </Grid>
                {kp?.nr !== "F" && (
                  <Grid>
                    <FBTextField
                      id="wrongvalue"
                      style={{ width: "10ch" }}
                      path={`/eventsdata/${eventId}/kp/${kpId}/wrongpenalty`}
                      value={archived ? kp.wrongpenalty : undefined}
                      margin="normal"
                      variant="outlined"
                      type="number"
                      inputProps={{
                        style: { textAlign: "center" },
                        readOnly: archived,
                      }}
                    />
                  </Grid>
                )}
              </>
            )}

            {kp?.nr !== "F" && !asLineKP && (
              <Grid>
                <FBTextField
                  path={`/eventsdata/${eventId}/kp/${kpId}/ra`}
                  tooltipfollow
                  id="ra"
                  select
                  label={t("kp.ra.label")}
                  value={archived ? kp.ra : undefined}
                  margin="normal"
                  variant="outlined"
                  style={{ width: "10ch" }}
                  inputProps={{
                    readOnly: archived,
                  }}
                >
                  {Object.keys(rakoef || {}).map((r) => (
                    <MenuItem key={r} value={r}>
                      {r}
                    </MenuItem>
                  ))}
                </FBTextField>
              </Grid>
            )}
            {kp?.nr !== "F" && kpgroups && (
              <Grid>
                <FBTextField
                  path={`/eventsdata/${eventId}/kp/${kpId}/grp`}
                  tooltipfollow
                  id="group"
                  select
                  label={t("kp.group.label")}
                  value={archived ? kp.grp : undefined}
                  margin="normal"
                  variant="outlined"
                  style={{ width: "10ch" }}
                  inputProps={{
                    readOnly: archived,
                  }}
                >
                  {Object.keys(kpgroups || {}).map((r) => (
                    <MenuItem key={r} value={r}>
                      {r}
                    </MenuItem>
                  ))}
                </FBTextField>
              </Grid>
            )}
            {kp?.nr !== "F" && (
              <Grid alignSelf="center">
                <TableContainer>
                  <Table size="small">
                    <ValueTableRow
                      name="correct"
                      color="lightgreen"
                      kpId={kpId}
                    />
                    {!kp.allok && (
                      <ValueTableRow
                        name="incorrect"
                        color="pink"
                        kpId={kpId}
                      />
                    )}
                    <ValueTableRow name="unmarked" color="white" kpId={kpId} />
                  </Table>
                </TableContainer>
              </Grid>
            )}
            <Grid size={{ xs: 12, sm: 6 }}>
              <FBTextField
                path={`/eventsdata/${eventId}/kpdata/${kpId}/desc`}
                id="desc"
                margin="normal"
                variant="outlined"
                value={archived ? kpData.desc : undefined}
                fullWidth
                inputProps={{
                  readOnly: archived,
                }}
              />
            </Grid>
            {!asLineKP && (
              <>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <FBTextField
                    id="longdesc"
                    path={`/eventsdata/${eventId}/kpdata/${kpId}/longdesc`}
                    multiline
                    variant="outlined"
                    value={archived ? kpData.longdesc : undefined}
                    maxRows={5}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="edit long description in Rich editor"
                            onClick={() => {
                              this.props.setRichEditPath(
                                `/eventsdata/${eventId}/kpdata/${kpId}/longdesc`
                              );
                            }}
                            size="large"
                          >
                            <Edit />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      readOnly: archived,
                    }}
                  />
                </Grid>
                <Grid>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={kp?.nr === "F"}
                        onChange={() => {
                          this.props.fbSet(
                            `/eventsdata/${eventId}/kp/${kpId}/nr`,
                            kp?.nr === "F" ? null : "F",
                            t("kp.finiskpchange")
                          );
                        }}
                        readOnly={archived}
                      />
                    }
                    label={t("kp.finishkp")}
                  />
                </Grid>
                {kp?.nr !== "F" && (
                  <Grid>
                    <FBSwitch
                      id="mandatory"
                      path={`/eventsdata/${eventId}/kp/${kpId}/kohustuslik`}
                      checked={archived ? kp.kohustuslik : undefined}
                      readOnly={archived}
                    />
                  </Grid>
                )}
                <Grid>
                  <FBSwitch
                    id="allok"
                    path={`/eventsdata/${eventId}/kp/${kpId}/allok`}
                    checked={archived ? kp.allok : undefined}
                    readOnly={archived}
                  />
                </Grid>
                {kp.allok && (
                  <Grid>
                    <FBSwitch
                      id="isauto"
                      path={`/eventsdata/${eventId}/kp/${kpId}/isauto`}
                      checked={archived ? kp.isauto : undefined}
                      readOnly={archived}
                    />
                  </Grid>
                )}
                <Grid>
                  <DependKPCombo
                    eventId={eventId}
                    kpId={kpId}
                    dependkp={kp.dependkp}
                    archived={archived}
                  />
                </Grid>

                {kp?.nr !== "F" && (
                  <Grid>
                    <FBSwitch
                      id="tyhistatud"
                      path={`/eventsdata/${eventId}/kp/${kpId}/tyhistatud`}
                      checked={archived ? kp.tyhistatud : undefined}
                      readOnly={archived}
                    />
                  </Grid>
                )}
                <Grid>
                  <FBTextField
                    id="maxanswerdistance"
                    path={`/eventsdata/${eventId}/kp/${kpId}/maxanswerdistance`}
                    type="number"
                    margin="normal"
                    variant="outlined"
                    value={archived ? kp.maxanswerdistance : undefined}
                    inputProps={{
                      readOnly: archived,
                    }}
                  />
                </Grid>

                <Grid>
                  {evnokpopenwhennotclose ? (
                    <FormControlLabel
                      control={<Switch checked />}
                      label={t("fbf.noopenwhennotclose.setonevent")}
                    />
                  ) : (
                    <FBSwitch
                      id="noopenwhennotclose"
                      path={`/eventsdata/${eventId}/kp/${kpId}/noopenwhennotclose`}
                      checked={archived ? kp.noopenwhennotclose : undefined}
                      readOnly={archived}
                    />
                  )}
                </Grid>
                <Grid>
                  <FBLatLng
                    id="kploc"
                    path={`/eventsdata/${eventId}/kpdata/${kpId}/loc`}
                  />
                </Grid>
                <Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.setState({ turntolinekp: true })}
                  >
                    {t("button.changetolinekp")}
                  </Button>
                </Grid>
                <Grid>
                  <FBSwitch
                    id="inwork"
                    path={`/eventsdata/${eventId}/kp/${kpId}/inwork`}
                    checked={archived ? kp.inwork : undefined}
                    readOnly={archived}
                  />
                </Grid>
              </>
            )}
            {asLineKP && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (kpData.a) {
                    fbSet(
                      `/eventsdata/${eventId}/kpdata/${kpId}/a`,
                      null,
                      "Changed KP type"
                    );
                  }
                  this.setState({ turntolinekp: false });
                }}
              >
                {t("button.changetonormalkp")}
              </Button>
            )}
          </Grid>
          <Grid container spacing={2}>
            {!kp?.allok && !asLineKP && (
              <Grid size={{ xs: 12, sm: 6 }}>
                {responses && Object.keys(responses).length > 0 && (
                  <Card>
                    <CardHeader title={t("kp.valikvastused")} />
                    <CardContent>
                      <List>
                        {responses &&
                          Object.keys(responses).map((respid) => (
                            <ListItem
                              key={respid}
                              dense
                              onClick={
                                archived
                                  ? undefined
                                  : () =>
                                      fbSet(
                                        `/eventsdata/${eventId}/kpanswer/${kpId}`,
                                        respid
                                      )
                              }
                              style={{
                                paddingRight: 96,
                              }}
                            >
                              {respid === answer && (
                                <ListItemAvatar>
                                  <CheckCircleTwoTone sx={{ color: "green" }} />
                                </ListItemAvatar>
                              )}
                              {this.state.editrespid === respid ? (
                                <ValikVastusEditor
                                  inset={respid !== answer}
                                  value={responses[respid]}
                                  submitValue={(v) =>
                                    this.updateKPResponse(respid, v)
                                  }
                                  cancelEdit={() =>
                                    this.setState({ editrespid: undefined })
                                  }
                                />
                              ) : (
                                <ListItemText
                                  inset={respid !== answer}
                                  primary={responses[respid]}
                                />
                              )}
                              {!archived && (
                                <ListItemSecondaryAction>
                                  {this.state.editrespid !== respid && (
                                    <IconButton
                                      aria-label="Edit"
                                      onClick={() => {
                                        this.setState({ editrespid: respid });
                                      }}
                                      key="Edit"
                                      size="large"
                                    >
                                      <Edit />
                                    </IconButton>
                                  )}
                                  <IconButton
                                    edge="end"
                                    aria-label="Delete"
                                    onClick={() =>
                                      this.rmKPResponse(
                                        respid,
                                        responses[respid]
                                      )
                                    }
                                    key="Delete"
                                    size="large"
                                  >
                                    <Delete />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              )}
                            </ListItem>
                          ))}
                        <ValikVastusEditor
                          disabled={kp?.allok}
                          submitValue={this.addKPResponse}
                        />
                      </List>
                    </CardContent>
                  </Card>
                )}
                {!(responses && Object.keys(responses).length > 0) && (
                  <div style={{ display: "inline-block" }}>
                    <List dense={true}>
                      <ValikVastusEditorListItem
                        disabled={Boolean(kp?.allok)}
                        addKPResponse={this.addKPResponse}
                      />
                    </List>
                  </div>
                )}
              </Grid>
            )}
            <Grid size={{ xs: 12, sm: 6 }}>
              <Card>
                <CardHeader title={t("kp.statistics")} />
                <CardContent>
                  <SingleKPStatistics eventId={eventId} kpId={kpId} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this.props.closeDialog}>
            {t("button.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connector(withNL(withTranslation()(EditKP)));
