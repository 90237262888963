import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  useTranslation,
  withTranslation,
  WithTranslation,
} from "react-i18next";
import { closeDialog, openDialog } from "rx/dialogsSlice";
import BaseTable, { Column, ColumnShape, AutoResizer } from "react-base-table";
import { Edit } from "@mui/icons-material";
import "react-base-table/styles.css";
import FBKeyboardDateTimePicker from "./FBKeyboardDateTimePicker";
import FBKeyboardTimePicker from "./FBKeyboardTimePicker";
import FBSwitch from "./FBSwitch";
import { withNL, WithNLProps } from "utils/NLContext";
import FBTextField from "./FBTextField";
import RestoreDeletedTeams, { DeletedTeamsEntry } from "./RestoreDeletedTeams";
import FBCheckbox from "./FBCheckbox";
import { setEditTeamId } from "rx/appStateSlice";
import { getDatabase, onValue, ref, Unsubscribe } from "firebase/database";
import { TimePickerProps } from "@mui/x-date-pickers/TimePicker";
import { DateTimePickerProps } from "@mui/x-date-pickers";
import "@mui/lab";
import AssignStartTimes from "./AssignStartTimes";
import { Dayjs } from "dayjs";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";

const mapState = (state: RootState) => ({
  teamsList: state.teamsList,
  teamsData: state.teamsData,
  sfwithseconds: state.event.sfwithseconds || false,
  sfwithdate: state.event.sfwithdate || false,
  klassid: state.event.klassid,
  ly: state.event.ly,
});
const mapDispatch = {
  closeDialog: () => closeDialog("teamsData"),
  openTeam: (tid: string) => setEditTeamId(tid),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type EditTeamsProps = PropsFromRedux &
  WithTranslation &
  WithNLProps & {
    eventId: string;
  };

type EditTeamsState = {
  timeWithSeconds: boolean;
  filterStr: string;
  restoreDeletedTeamsOpen: boolean;
  assignStartTimes: boolean;
  deletedTeams: {
    [key: string]: DeletedTeamsEntry;
  } | null;
};
type DataEntry = {
  id: string;
};
let cols: ColumnShape<DataEntry>[] = [];
cols.push({
  key: "team",
  title: "Team",
  dataKey: "teamname",
  frozen: Column.FrozenDirection.LEFT,
  width: 150,
});

cols.push({
  key: "team",
  title: "Other",
  dataKey: "teamname",
  width: 150,
});

const WheelOfNamesButton: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        dispatch(openDialog("wheelofnames"));
      }}
    >
      {t("button.wheelofnames")}
    </Button>
  );
};

class EditTeams extends React.Component<EditTeamsProps, EditTeamsState> {
  state: EditTeamsState = {
    timeWithSeconds: false,
    restoreDeletedTeamsOpen: false,
    assignStartTimes: false,
    deletedTeams: null,
    filterStr: "",
  };
  unsubscribeDeletedTeams?: Unsubscribe;
  componentDidMount() {
    onValue(
      ref(getDatabase(), "/eventsdata/" + this.props.eventId + "/deletedteams"),
      (snap) => {
        this.setState({ deletedTeams: snap.val() });
      }
    );
  }
  componentWillUnmount() {
    this.unsubscribeDeletedTeams && this.unsubscribeDeletedTeams();
  }

  columns: ColumnShape<DataEntry>[] = [
    {
      key: "editbutton",
      title: "",
      width: 70,
      frozen: Column.FrozenDirection.LEFT,
      cellRenderer: ({ rowData }) => {
        return (
          <IconButton
            onClick={() => this.props.openTeam(rowData.id)}
            size="large"
          >
            <Edit />
          </IconButton>
        );
      },
    },
    {
      key: "nr",
      title: this.props.t("team.header.nr"),
      width: 60,
      frozen: Column.FrozenDirection.LEFT,
      cellRenderer: ({ column, rowIndex, rowData }) => {
        return <Typography>{this.props.teamsList[rowData.id].nr}</Typography>;
      },
    },
    {
      key: "team",
      title: this.props.t("team.header.team"),
      width: 200,
      frozen: Column.FrozenDirection.LEFT,
      cellRenderer: ({ column, rowIndex, rowData }) => {
        return (
          <Typography noWrap>
            {this.props.teamsList[rowData.id].name}
          </Typography>
        );
      },
    },
    {
      key: "start",
      title: this.props.t("team.header.start"),
      width: this.props.sfwithdate ? 230 : 150,
      cellRenderer: ({ column, rowIndex, rowData }) => {
        return this.renderTime(rowData.id, "starttime");
      },
    },
    {
      key: "finish",
      title: this.props.t("team.header.finish"),
      width: this.props.sfwithdate ? 230 : 150,
      cellRenderer: ({ column, rowIndex, rowData }) => {
        let errorMsg: string | undefined;
        const tdata = this.props.teamsList[rowData.id];
        if (
          tdata.starttime &&
          tdata.finishtime &&
          tdata.starttime > tdata.finishtime
        )
          errorMsg = this.props.t("teams.error.finishafterstart");
        return this.renderTime(rowData.id, "finishtime", errorMsg);
      },
    },
    {
      key: "disabled",
      title: this.props.t("team.header.disabled"),
      width: 130,
      align: "center",
      cellRenderer: ({ column, rowIndex, rowData }) => {
        const { eventId } = this.props;
        return (
          <FBCheckbox path={`/teams/${eventId}/list/${rowData.id}/disabled`} />
        );
      },
    },
    {
      key: "klassid",
      title: this.props.t("team.header.classes"),
      width: 180,
      cellRenderer: ({ column, rowIndex, rowData }) => {
        const { klassid, teamsList } = this.props;
        if (!klassid) return "";
        return Object.keys(klassid).length > 2 ? (
          <FormControl>
            <Select
              multiple
              value={Object.keys(teamsList[rowData.id].klassid || {})}
              renderValue={(selected: any) => (
                <div>
                  {selected.map((value: string) => (
                    <Chip key={value} label={value} />
                  ))}
                </div>
              )}
            >
              {Object.entries(klassid).map(([kltahis, klname]) => (
                <MenuItem key={kltahis} value={kltahis}>
                  <Checkbox
                    onChange={(_, c) =>
                      this.setTeamClassChange(rowData.id, kltahis, c)
                    }
                    checked={
                      Boolean((teamsList[rowData.id].klassid || {})[kltahis]) ||
                      false
                    }
                  />
                  <ListItemText
                    primary={typeof klname === "string" ? klname : kltahis}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <div>
            <FormGroup row>
              {Object.entries(klassid).map(([kltahis, klname]) => (
                <FormControlLabel
                  key={kltahis}
                  control={
                    <Checkbox
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      checked={!!(teamsList[rowData.id].klassid || {})[kltahis]}
                      value={kltahis}
                      onChange={(_, c) =>
                        this.setTeamClassChange(rowData.id, kltahis, c)
                      }
                    />
                  }
                  label={typeof (klname) === "string" ? klname : kltahis}
                />
              ))}
            </FormGroup>
          </div>
        );
      },
    },
    {
      key: "katk",
      title: this.props.t("team.header.katk"),
      width: 70,
      align: "center",
      cellRenderer: ({ column, rowIndex, rowData }) => {
        const { eventId } = this.props;
        return (
          <FBCheckbox path={`/teams/${eventId}/list/${rowData.id}/katk`} />
        );
      },
    },
    {
      key: "dsq",
      title: this.props.t("team.header.dsq"),
      width: 70,
      align: "center",
      cellRenderer: ({ column, rowIndex, rowData }) => {
        const { eventId } = this.props;
        return <FBCheckbox path={`/teams/${eventId}/list/${rowData.id}/dsq`} />;
      },
    },
  ];

  setTeamClassChange = (tid: string, klassid: string, checked: boolean) => {
    const { eventId, t } = this.props;
    const klasspath = `/teams/${eventId}/list/${tid}/klassid/${klassid}`;
    if (checked) this.props.fbSet(klasspath, true, t("teams.addclass"));
    else this.props.fbRemove(klasspath, t("teams.rmclass"));
  };

  renderTime = (tid: string, type: string, errorMsg?: string) => {
    let format = "HH:mm";
    let views: any = ["hours", "minutes"];
    if (this.props.sfwithseconds) {
      format += ":ss";
      views.push("seconds");
    }
    if (this.props.sfwithdate) {
      format = "DD/MM/YYYY " + format;
    }
    type DateTimeP = Omit<DateTimePickerProps<Dayjs>, "value" | "onChange"> & {
      path: string;
    };
    type TimeP = Omit<TimePickerProps<Dayjs>, "value" | "onChange"> & {
      path: string;
    };
    const pickerProps: DateTimeP | TimeP = {
      ampm: false,
      format: format,
      path: "/teams/" + this.props.eventId + "/list/" + tid + "/" + type,
    };

    if (this.props.sfwithdate)
      return (
        <FBKeyboardDateTimePicker
          disableTooltip
          id="teamsftime"
          {...(pickerProps as DateTimeP)}
          slotProps={{
            textField: {
              className: "timepickerclass",
              label: "",
              variant: "standard",
              error: Boolean(errorMsg),
              helperText: errorMsg,
            },
            actionBar: {
              actions: ["clear"],
            },
          }}
        />
      );
    else
      return (
        // TODO add endadormnt to clear time
        <FBKeyboardTimePicker
          useTimeWithDate
          disableTooltip
          {...(pickerProps as TimeP)}
          tkey="teamsftime"
          slotProps={{
            textField: {
              className: "timepickerclass",
              label: "",
              variant: "standard",
              error: Boolean(errorMsg),
              helperText: errorMsg,
            },
            actionBar: {
              actions: ["clear"],
            },
          }}
        />
      );
  };
  render() {
    const { t, teamsList, teamsData, eventId, ly } = this.props;

    const filterStr = this.state.filterStr.toLowerCase();
    this.columns[1].hidden = true;
    const sfwidth = this.props.sfwithdate ? 230 : 150;
    if (sfwidth !== this.columns[3].width) {
      this.columns[3].width = sfwidth;
      this.columns[4].width = sfwidth;
    }
    // It would be good to have next two lines in previous if, but it will stop working after that. :(
    this.columns[3] = Object.assign({}, this.columns[3]);
    this.columns[4] = Object.assign({}, this.columns[4]);

    const teams = Object.keys(teamsList)
      .filter((tid) => {
        if (filterStr.trim().length === 0) return true;
        if (teamsList[tid].name?.toLowerCase().includes(filterStr)) return true;
        if (teamsData[tid].displayName?.toLowerCase().includes(filterStr))
          return true;
        if (teamsData[tid].email?.toLowerCase().includes(filterStr))
          return true;
        if (teamsData[tid].phoneNumber?.toLowerCase().includes(filterStr))
          return true;
        for (let dev of Object.values(teamsData[tid].devs)) {
          if (dev.brand?.toLowerCase().includes(filterStr)) return true;
          if (dev.model?.toLowerCase().includes(filterStr)) return true;
          if (dev.manufacturer?.toLowerCase().includes(filterStr)) return true;
        }
        return false;
      })
      .map((tid) => {
        return {
          id: tid,
        };
      });
    let lycolumns: ColumnShape<DataEntry>[] = [];
    if (ly) {
      Object.entries(ly).forEach(([lyid, onely]) => {
        lycolumns.push({
          key: lyid,
          title: onely.name,
          width: 100,
          cellRenderer: ({ column, rowIndex, rowData }) => {
            const { eventId } = this.props;
            return (
              <FBTextField
                type="number"
                path={`/teams/${eventId}/list/${rowData.id}/lypoints/${lyid}`}
              />
            );
          },
        });
      });
    }

    let allcolumns = [...this.columns, ...lycolumns];
    var inputwidth = 6;
    if (this.props.sfwithseconds) inputwidth += 3;
    if (this.props.sfwithdate) inputwidth += 11;

    return (
      <Dialog open={true} maxWidth="lg" fullWidth fullScreen={isMobile}>
        <DialogTitle>{t("title.editteams")}</DialogTitle>
        <DialogContent>
          <Box sx={{ paddingBottom: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid>
                <FBSwitch
                  path={`/eventsdata/${eventId}/data/sfwithseconds`}
                  id="sfwithseconds"
                />
              </Grid>
              <Grid>
                <FBSwitch
                  path={`/eventsdata/${eventId}/data/sfwithdate`}
                  id="sfwithdate"
                />
              </Grid>
              <Grid>
                <TextField
                  focused
                  autoFocus
                  label={t("teams.filter")}
                  value={this.state.filterStr}
                  onChange={(event) =>
                    this.setState({ filterStr: event.target.value })
                  }
                />
              </Grid>
              <Grid>
                <Button
                  variant="contained"
                  onClick={() => this.setState({ assignStartTimes: true })}
                >
                  {t("button.assignstarttimes")}
                </Button>
              </Grid>
              {this.state.deletedTeams !== null && (
                <Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      this.setState({ restoreDeletedTeamsOpen: true })
                    }
                  >
                    {t("button.restoredeletedteams")}
                  </Button>
                </Grid>
              )}
              <Grid>
                <WheelOfNamesButton />
              </Grid>
            </Grid>
            {this.state.restoreDeletedTeamsOpen && (
              <RestoreDeletedTeams
                teams={this.state.deletedTeams || {}}
                eventId={eventId}
                onClose={() =>
                  this.setState({ restoreDeletedTeamsOpen: false })
                }
              />
            )}
            <AssignStartTimes
              open={this.state.assignStartTimes}
              onClose={() => this.setState({ assignStartTimes: false })}
            />
          </Box>
          <Box
            height="70vh"
            sx={{
              "& .timepickerclass": {
                width: `calc(${inputwidth}ch + 40px)`,
              },
            }}
          >
            <AutoResizer>
              {({ width, height }) => (
                <BaseTable
                  fixed
                  width={width}
                  height={height}
                  columns={allcolumns}
                  data={teams}
                />
              )}
            </AutoResizer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this.props.closeDialog}>
            {t("button.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connector(withTranslation()(withNL(EditTeams)));
