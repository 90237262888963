import { Grid, Typography, IconButton, Button } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "rx/store";
import FBTextField from "./FBTextField";
import FBKeyboardDateTimePicker from "./FBKeyboardDateTimePicker";
import QrCodeIcon from "../icons/QrCode.svg";
import QrCodeDialog from "./QrCodeDialog";
import NLTooltip from "./NLTooltip";
import FBSwitch from "./FBSwitch";
import { useTranslation } from "react-i18next";
import { donwloadEvent } from "./DownloadsDialog";

const DownloadButton: React.FC<{ eventId: string }> = ({ eventId }) => {
  const { t } = useTranslation();

  return (
    <Button variant="contained" onClick={async () => donwloadEvent}>
      {t("button.eventdownload")}
    </Button>
  );
};
const EventSettingsGeneral: React.FC<{ eventId: string }> = ({ eventId }) => {
  const conf = useSelector((state: RootState) => state.conf);
  const [showQr, setQr] = useState(null as string | null);
  const evlink = `https://${conf.hostname}/${eventId}`;
  return (
    <Grid container spacing={2} alignItems="center" direction="row">
      {showQr !== null && (
        <QrCodeDialog
          message={showQr}
          onClose={() => {
            setQr(null);
          }}
        />
      )}
      <Grid size={{ xs: 12, sm: 4, lg: 2 }}>
        <FBTextField
          disabletooltip
          id="eventName"
          path={`/events/${eventId}/name`}
          variant="outlined"
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 8, lg: 4 }}>
        <Grid container direction="row" wrap="nowrap" alignItems="center">
          <Grid style={{ maxWidth: "300px" }}>
            <NLTooltip message={evlink}>
              <Typography noWrap={true}>{evlink}</Typography>
            </NLTooltip>
          </Grid>
          <Grid>
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(evlink);
              }}
              size="large"
            >
              <ContentCopy />
            </IconButton>
          </Grid>
          <Grid>
            <IconButton
              onClick={() => {
                setQr(evlink);
              }}
              size="large"
            >
              <img src={QrCodeIcon} alt="React Logo" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
        <FBKeyboardDateTimePicker
          ampm={false}
          format="DD/MM/YYYY HH:mm"
          id="starttime"
          path={`/events/${eventId}/starttime`}
          slotProps={{
            actionBar: {
              actions: ["today"],
            },
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
        <FBKeyboardDateTimePicker
          ampm={false}
          format="DD/MM/YYYY HH:mm"
          id="endtime"
          path={`/events/${eventId}/endtime`}
          slotProps={{
            actionBar: {
              actions: ["today"],
            },
          }}
        />
      </Grid>
      <Grid>
        <DownloadButton eventId={eventId} />
      </Grid>
      <Grid>
        <FBSwitch
          id="hidetracks"
          path={`/eventsdata/${eventId}/data/hidetracks`}
        />
      </Grid>
      <Grid>
        <FBSwitch id="hidden" path={`/events/${eventId}/hidden`} />
      </Grid>
    </Grid>
  );
};

export default EventSettingsGeneral;
