import { connect, ConnectedProps } from "react-redux";
import { Component } from "react";
import { TrackDecoder } from "@nutilogi/trzip";
import store, { RootState } from "rx/store";
import { addPoints } from "rx/tracksSlice";
import { issueType, addIssue, IssueTypes } from "rx/appStateSlice";
import { getAuth, Unsubscribe, User } from "firebase/auth";
import { getDatabase, onValue, ref } from "firebase/database";
import {
  getDownloadURL,
  getStorage,
  listAll,
  ref as storageRef,
} from "firebase/storage";
import { PointData } from "@nutilogi/nutilogitypes";

export const defaultLocSendHost = "nutilocation.vessmann.ee";

const mapState = (state: RootState) => ({
  teams: state.teamsList,
});
const mapDispatch = {
  addPoints: (tid: string, devid: string, points: PointData[]) =>
    addPoints({ tid, devid, points }),
  addIssue: (issue: issueType) => addIssue(issue),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type TracksDataListenerProps = PropsFromRedux & {
  eventId: string;
};
type TracksDataListenerState = {
  locsendhost: string;
  user?: User | null;
};
class TracksDataListener extends Component<
  TracksDataListenerProps,
  TracksDataListenerState
> {
  state: TracksDataListenerState = {
    locsendhost: defaultLocSendHost,
  };
  errorcount: number = 0;
  devtoteammap: { [devid: string]: string } = {};
  hostconfref = ref(getDatabase(), "/conf/locsendhost");
  unmounting = false;
  wsstarttime = 0;
  ws?: WebSocket;
  unsubscribeAuth?: Unsubscribe;
  unsubscribeHostConf?: Unsubscribe;

  componentDidMount() {
    this.unsubscribeHostConf = onValue(this.hostconfref, (snap) => {
      this.setState({ locsendhost: snap.val() || defaultLocSendHost });
    });
    this.unsubscribeAuth = getAuth().onAuthStateChanged((user) => {
      this.setState({ user: user });
    });
    this.loadTrakcsFromStorage();
  }

  async loadTrakcsFromStorage() {
    const listing = await listAll(
      storageRef(getStorage(), "/nutilogi/events/" + this.props.eventId)
    );
    listing.items.forEach((lit) => {
      //console.log(lit, lit.fullPath, lit.name);
      if (!lit.name.endsWith("_ws.data")) {
        return;
      }
      getDownloadURL(lit)
        .then((url) => fetch(url))
        .then((r) => r.arrayBuffer())
        .then((abuf) => this.applyEncodedBuffer(new Uint8Array(abuf)));
    });
  }

  fixTrack(points: PointData[]) {
    let np: PointData[] = [];
    let ct = 0;
    //const sp = points.sort((a, b) => a.t - b.t);
    for (let i = 0; i < points.length; i++) {
      const p = points[i];
      if (p.t > ct) {
        np.push(p);
        ct = p.t;
      } else if (p.t === ct) {
        /*
        if (!isSamePoint(p, points[i - 1]))
          console.log("Found bad duplicate", p, points[i - 1]);
          */
      }
    }
    return np;
  }

  applyEncodedBuffer(buf: Uint8Array) {
    let decodeddata = TrackDecoder.decode(Buffer.from(buf));
    Object.entries(decodeddata).forEach(([tid, tdata]) => {
      Object.entries(tdata).forEach(([devid, points]) => {
        if (points.length > 0)
          this.props.addPoints(tid, devid, this.fixTrack(points));
      });
    });
  }

  startWs = () => {
    if (this.unmounting) return;
    this.wsstarttime = new Date().getTime();
    this.ws = new WebSocket("wss://" + this.state.locsendhost + "/listen");
    this.ws.onopen = () => {
      this.errorcount = 0;
      this.ws?.send("EventId:" + this.props.eventId);
      const { user } = this.state;
      if (user) {
        user.getIdToken().then((idToken) => {
          this.ws?.send("BearerToken:" + idToken);
        });
      }
      //this.ws?.send("BroadcastMessage:Hello World");
    };
    this.ws.onclose = (ev) => {
      if (this.unmounting) return;
      this.restartWs();
    };
    this.ws.onerror = (ev) => {
      this.errorcount++;
      console.log("onerror", ev);
    };
    this.ws.onmessage = (msg) => {
      if (typeof msg.data === "string") {
        if (msg.data.startsWith("j:")) {
          const data = JSON.parse(msg.data.substr(2));
          data.forEach((de: any) => this.dataReceived(de));
        }
      } else if (msg.data instanceof Blob) {
        msg.data.arrayBuffer().then((abuf) => {
          let buf = new Uint8Array(abuf);
          if (Buffer.from(buf.subarray(0, 2)).toString("utf-8") === "z:") {
            this.applyEncodedBuffer(buf.subarray(2));
          }
        });
      }
      //console.log("got message !:", msg.data, msg);
    };
  };

  dataReceived(data: any) {
    if (!(data.devid in this.devtoteammap)) {
      const team = Object.entries(this.props.teams).find(
        ([tid, teamdata]) => teamdata.devs && data.devid in teamdata.devs
      );
      if (team === undefined) {
        const issues = (store.getState() as RootState).appState.issues;
        const isReported = issues.find(
          (i) =>
            i.type === IssueTypes.GPSForUnknownDev &&
            i.data.devid === data.devid &&
            i.data.uid === data.uid
        );
        if (isReported) return;
        this.props.addIssue({
          id: data.devid + data.uid,
          title: "issues.unknowngpsdev.label",
          type: IssueTypes.GPSForUnknownDev,
          data: data,
        });
        console.log("location for team that we dont know about, skipping.");
        return;
      }
      this.devtoteammap[data.devid] = team[0];
    }

    this.props.addPoints(
      this.devtoteammap[data.devid],
      data.devid,
      data.locations
    );
  }

  restartWs() {
    if (this.ws) this.ws.close();
    const lastConnectms = new Date().getTime() - this.wsstarttime;
    let waitBeforeReconnect = 0;
    if (lastConnectms < 1000 && this.errorcount === 0) {
      waitBeforeReconnect = 3000;
    }
    if (this.errorcount > 0) {
      waitBeforeReconnect = 10000 * this.errorcount;
    }
    if (waitBeforeReconnect > 0) {
      if (waitBeforeReconnect > 300000) {
        waitBeforeReconnect = 300000;
      }
      console.log("delaying restart with ", waitBeforeReconnect);
      setTimeout(() => {
        this.startWs();
      }, waitBeforeReconnect);
      return;
    }
    this.startWs();
  }

  closeWs = () => {
    this.ws?.close();
    this.ws = undefined;
  };
  componentDidUpdate(
    _prevProps: TracksDataListenerProps,
    prevState: TracksDataListenerState
  ) {
    if (this.state.locsendhost !== prevState.locsendhost) {
      this.closeWs();
    }
    if (this.state.user !== prevState.user) {
      if (prevState.user === undefined) {
        this.startWs();
      } else this.closeWs();
    }
  }

  componentWillUnmount() {
    this.unsubscribeHostConf && this.unsubscribeHostConf();
    this.unsubscribeAuth && this.unsubscribeAuth();
    if (this.ws) {
      this.unmounting = true;
      this.ws.close();
      delete this.ws;
    }
  }

  render() {
    return null;
  }
}

export default connector(TracksDataListener);
