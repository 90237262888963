import { Button, Grid, TextField } from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useMemo, useState } from "react";
import EArveldajaClient, {
  GetAccountDimensionEntry,
} from "utils/earveldajaclient";
import { useQuery } from "@tanstack/react-query";
import currency from "currency.js";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

const StockAccountsWidget: React.FC<{
  eaclient: EArveldajaClient;
  sa: StockAccounts[];
  date: dayjs.Dayjs;
}> = ({ eaclient, sa, date }) => {
  const [target1, setTarget1] = useState<{ [k: number]: number }>({});
  const [target2, setTarget2] = useState<{ [k: number]: number }>({});
  const [kanded, setKanded] = useState<{ [k: number]: number }>({});
  const inrows = useMemo(() => {
    return sa.map((e) => {
      return {
        id: e.accdim.id,
        acname: e.accdim.title_est,
        saldo: e.saldo,
        accdim: e.accdim,
      };
    });
  }, [sa]);
  const rows = inrows.map((e, idx) => {
    return Object.assign({}, e, {
      total:
        target1[e.id] || target2[e.id]
          ? currency(target1[e.id] || 0).add(currency(target2[e.id] || 0)).value
          : undefined,
    });
  });
  const columns: GridColDef[] = [
    { field: "acname", headerName: "Konto", width: 350 },
    { field: "saldo", headerName: "Saldo", width: 150 },
    {
      field: "target1",
      headerName: "Target 1",
      width: 150,
      renderCell: (p) => {
        return (
          <TextField
            onChange={(v) => {
              setTarget1((s) => {
                return Object.assign({}, s, { [p.id]: v.target.value });
              });
            }}
          />
        );
      },
    },
    {
      field: "target2",
      headerName: "Target 2",
      width: 150,
      renderCell: (p) => {
        return (
          <TextField
            onChange={(v) => {
              setTarget2((s) => {
                return Object.assign({}, s, { [p.id]: v.target.value });
              });
            }}
          />
        );
      },
    },
    {
      field: "total",
      headerName: "Kokku",
      width: 150,
    },
    {
      field: "change",
      headerName: "Muutus",
      width: 150,
      renderCell: (p) => {
        const v = p.row.total;
        if (v !== undefined) {
          //                     insert_date: dayjs().toISOString().substring(0, 10),
          let chvalue = currency(p.row.saldo).subtract(currency(v));
          let cvalue = chvalue.value;
          let kasum = true;
          if (cvalue < 0) {
            kasum = false;
            cvalue = -cvalue;
          }
          if (kanded[p.id as number]) {
            return chvalue.multiply(-1).format({ symbol: "€ " });
          }
          return (
            <Button
              variant="contained"
              onClick={() => {
                const m = p.row.acname.match(/\(([^)]+)\)/);
                if (m) {
                  eaclient
                    .CreateJournalEntry({
                      effective_date: date.format("YYYY-MM-DD"),
                      document_number: date.format("DD/YYYY"),
                      operation_type: "ENTRY",
                      title: `${m[1]} väärtuse uuendamine`,
                      postings: [
                        {
                          accounts_dimensions_id: p.row.accdim.id,
                          accounts_id: p.row.accdim.accounts_id,
                          type: kasum ? "C" : "D",
                          amount: cvalue,
                          base_amount: cvalue,
                          cl_currencies_id: "EUR",
                        },
                        {
                          accounts_id: 8330,
                          type: kasum ? "D" : "C",
                          amount: cvalue,
                          base_amount: cvalue,
                          cl_currencies_id: "EUR",
                        },
                      ],
                    })
                    .then((r) => {
                      console.log(r.created_object_id);
                      eaclient.GetJournal(r.created_object_id).then((v) => {
                        console.log("Number:", v.number);
                        setKanded((s) => {
                          return Object.assign({}, s, {
                            [p.id]: v.number,
                          });
                        });
                      });
                      eaclient.RegisterJournalEntry(r.created_object_id);
                    });
                }
              }}
            >
              {chvalue.multiply(-1).format({ symbol: "€" })}
            </Button>
          );
        } else {
          return null;
        }
      },
    },
    {
      field: "kanne",
      headerName: "Kanne",
      width: 150,
      renderCell: (p) => {
        if (kanded[p.id as number] !== undefined) {
          return kanded[p.id as number];
        }
        return null;
      },
    },
  ];
  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
};

type EArveldajaAccessType = {
  ApiKeyID: string;
  ApiKeyPublic: string;
  password: string;
};

type StockAccounts = {
  accdim: GetAccountDimensionEntry;
  saldo: currency;
};

const AccountingDialog: React.FC = () => {
  const creds = useQuery<EArveldajaAccessType>({
    queryKey: ["apiaccess"],
    queryFn: async () => {
      const getArveldajaAccessData = httpsCallable<undefined, string>(
        getFunctions(undefined, "europe-west1"),
        "api/earv"
      );
      const r = await getArveldajaAccessData();
      console.log("Got data", r);
      return JSON.parse(r.data);
    },
  });
  const [stockdate, setStockDate] = useState(
    dayjs().startOf("year").subtract(1, "day")
  );
  const [stockaccounts, setStockAccounts] = useState<StockAccounts[]>([]);
  const [eapi, setEApi] = useState<EArveldajaClient>();

  useEffect(() => {
    if (!creds.isSuccess) return;
    console.log("Must create earveldaja", creds.isSuccess, creds.data);
    setEApi(
      new EArveldajaClient(
        creds.data.ApiKeyID,
        creds.data.ApiKeyPublic,
        creds.data.password
      )
    );
  }, [creds.data, creds.isSuccess]);
  return (
    <>
      {stockaccounts.length > 0 && eapi && (
        <StockAccountsWidget
          eaclient={eapi}
          sa={stockaccounts}
          date={stockdate}
        />
      )}
      <Grid>
        <Grid>
          <DatePicker
            value={stockdate}
            onChange={(v) => {
              if (v) setStockDate(v);
            }}
          />
        </Grid>
        <Grid>
          <Button
            variant="contained"
            disabled={!creds.isSuccess}
            onClick={async () => {
              const journals = await eapi?.GetJournals();
              const j1 = journals?.filter(
                (j) => j.document_number === "31/2022"
              );
              console.log(j1);
              const accdim = await eapi?.GetAccountDimensions();
              const onedim = (n: number) => {
                const tj = journals?.filter((j) => {
                  return j.postings?.find(
                    (p) => p.accounts_dimensions_id === n
                  );
                });
                let amount = currency(0);
                tj?.forEach((k) => {
                  if (dayjs(k.effective_date).isAfter(stockdate)) return;
                  const p = k.postings?.filter(
                    (p) => p.accounts_dimensions_id === n
                  );
                  p?.forEach((one) => {
                    amount = amount.add(
                      currency(one.amount).multiply(one.type === "D" ? 1 : -1)
                    );
                  });
                });
                return amount;
              };
              console.log(stockdate);

              const sacc = accdim?.filter((a) => a.accounts_id === 1100);
              let ar: StockAccounts[] = [];
              sacc?.forEach((a) => {
                const av = onedim(a.id);
                if (av.value !== 0) ar.push({ accdim: a, saldo: av });
                console.log(a.id, a.title_est, av.value);
              });
              setStockAccounts(ar);
            }}
          >
            Stock sync
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AccountingDialog;
