import { Grid, IconButton, Button, FormControlLabel, Switch, Card, CardContent, Table, TableRow, TableCell, CardHeader, Box, DialogContent, DialogContentText, Dialog, TextField, DialogActions, TableBody } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import React, { useState } from "react";
import FBSwitch from "./FBSwitch";
import { useTranslation } from "react-i18next";
import { NLTooltipWrapper } from "utils/SmallUtils";
import { useRFirebaseDb } from "utils/FirebaseDbWrapper";
import { useNL } from "utils/NLContext";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { getDatabase, ref } from "firebase/database";
import FBKeyboardDateTimePicker from "./FBKeyboardDateTimePicker";
import { useTSelector } from "rx/store";


export type RegData = {
  publicreglist?: boolean;
  enabled?: boolean;
  email?: boolean;
  emailpublic?: boolean;
  phone?: boolean;
  phonepublic?: boolean;
  car?: boolean;
  carpublic?: boolean;
  regend?: number;
  customfields?: {
    [fieldid: string]: {
      hint: string;
      label: string;
      s?: number;
      required?: boolean;
      type?: React.HTMLInputTypeAttribute;
      muitype?: "text" | "single" | "multiple" | "boolean";
      selectvalues?: string;
      defaultvalue?: string | boolean;
      public?: boolean;
    }
  }
}

const CustomFieldContents: React.FC<{ regdata: RegData, regpath: string }> = ({ regdata, regpath }) => {
  const { t } = useTranslation();
  const { fbUpdate, fbRemove } = useNL();
  const rows = Object.entries(regdata.customfields || {}).map(([f, v]) => {
    return Object.assign({ id: f }, v);
  })
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('reg.customf.header.id')
    },
    {
      field: 'muitype',
      headerName: t('reg.customf.header.type'),
      editable: true,
      type: 'singleSelect',
      valueOptions: [t('reg.muitype.text'), t('reg.muitype.single'), t('reg.muitype.multiple'), t('reg.muitype.bool')],
      valueGetter: (value) => {
        let resp = t('reg.muitype.text');
        if (value === 'single')
          resp = t('reg.muitype.single');
        else if (value === 'multiple')
          resp = t('reg.muitype.multiple');
        else if (value === 'boolean')
          resp = t('reg.muitype.bool');
        return resp;
      },
      valueSetter: (value, row) => {
        let muitype = 'text';
        if (value === t('reg.muitype.single'))
          muitype = 'single';
        else if (value === t('reg.muitype.multiple'))
          muitype = 'multiple';
        else if (value === t('reg.muitype.bool'))
          muitype = 'boolean';

        return { ...row, muitype }
      }
    },
    {
      field: 'selectvalues',
      editable: true,
      headerName: t('reg.customf.header.selectvalues'),
      valueSetter: (selectvalues, row) => {
        if (row.muitype !== "single" && row.muitype !== "multiple") {
          return row;
        }
        return { ...row, selectvalues };
      }
    },
    {
      field: 'label',
      editable: true,
      headerName: t('reg.customf.header.label')

    }, {
      field: 'hint',
      editable: true,
      headerName: t('reg.customf.header.hint')
    }, {
      field: 's',
      editable: true,
      type: 'number',
      headerName: t('reg.customf.header.order')
    }, {
      field: 'public',
      editable: true,
      type: 'boolean',
      headerName: t('reg.customf.header.public')
    }, {
      field: 'required',
      editable: true,
      type: 'boolean',
      headerName: t('reg.customf.header.required')
    }, {
      field: 'delete',
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Delete />}
          label="Delete"
          onClick={() => { fbRemove(ref(getDatabase(), `${regpath}/customfields/${params.id}`), t('reg.undo.deletecustomf')) }}
        />]
    }];
  return <Box sx={{ height: 300, width: '100%' }}>
    <DataGrid
      rows={rows}
      columns={columns}
      editMode="row"
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      processRowUpdate={(newRow, oldRow) => {
        console.log("Row update", oldRow, newRow);
        let updateData: { [k: string]: string | number | boolean } = {}
        Object.keys(newRow).forEach(f => {
          if (newRow[f] === oldRow[f]) return;
          updateData[f] = newRow[f];
        });
        if (Object.keys(updateData).length > 0) {
          fbUpdate(ref(getDatabase(), `${regpath}/customfields/${oldRow["id"]}`), updateData, t('reg.undo.updatedcustomf'))
        }
        return newRow;
      }}
      pageSizeOptions={[5]}
      disableRowSelectionOnClick
    />
  </Box>;
}

const AddCustomFieldButton: React.FC<{ regpath: string }> = ({ regpath }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errormsg, setErrorMsg] = useState<string>();
  const { fbSet } = useNL();
  const { t } = useTranslation();
  return <>
    <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} PaperProps={{
      component: 'form',
      onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const fieldid = formData.get("fieldid")?.toString();
        if (fieldid === undefined) return false;
        const isValidFirebaseKey = /^[^.$[\]#]{1,768}$/i.test(fieldid);
        if (!isValidFirebaseKey) {
          setErrorMsg(t("reg.error.badcharacter"));
          return false;
        }
        fbSet(`${regpath}/customfields/${fieldid}`, { label: fieldid });
        setDialogOpen(false);
      },
    }} >
      <DialogContent>
        <DialogContentText>{t('message.entercustomfieldid')}</DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="fieldid"
          name="fieldid"
          label={t('reg.fieldid.label')}
          type="text"
          fullWidth
          error={Boolean(errormsg)}
          helperText={errormsg}
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setDialogOpen(false)}>{t('button.cancel')}</Button>
        <Button variant="contained" type="submit">{t('button.submit')}</Button>
      </DialogActions>
    </Dialog>
    <IconButton onClick={() => {
      setErrorMsg(undefined);
      setDialogOpen(true);
    }}><Add /></IconButton></>;
}
const EventSettingsRegistration: React.FC<{ eventId: string }> = ({ eventId }) => {
  const { t } = useTranslation();
  const regpath = `/eventsdata/${eventId}/data/registration`;
  const regdata = useRFirebaseDb<RegData>(regpath)
  const joinrequiresconfirmation = useTSelector(s => s.event.joinrequiresconfirmation);
  const { fbRemove, fbSet } = useNL();
  return (
    <>
      <Grid container spacing={2} alignItems="center" direction="row" sx={{ paddingBottom: 2 }}>
        <Grid>

          <NLTooltipWrapper
            tkey={`fbf.enablereg.tooltip`}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={
                    Boolean(regdata)
                  }
                  onChange={
                    (_event, checked) => {
                      if (checked) {
                        fbSet(`/eventsdata/${eventId}/data/registration/enabled`, true, t('fbf.enablereg.undomsgforset'));
                      } else {
                        fbRemove(`/eventsdata/${eventId}/data/registration`, t('fbf.enablereg.undomsgforunset'));
                      }
                    }
                  }
                />
              }
              label={t(`fbf.enablereg.label`)}
            />
          </NLTooltipWrapper>
        </Grid>
        <Grid>
          <FBSwitch id="reglistpublic" path={`/eventsdata/${eventId}/data/registration/publicreglist`} />
        </Grid>
        <Grid>
          <FBSwitch disabled={!joinrequiresconfirmation || !regdata?.publicreglist} id="reglistallowedonly" path={`/eventsdata/${eventId}/data/registration/reglistallowedonly`} />
        </Grid>
        <Grid>
          <FBKeyboardDateTimePicker ampm={false} path={`/eventsdata/${eventId}/data/registration/regend`} id="regend"
            format="DD/MM/YYYY HH:mm"
            slotProps={{
              actionBar: {
                actions: ["clear", "today", "accept", "cancel"],
              },
            }} />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" direction="row">
        <Grid>
          <Card>
            <CardHeader title={t('title.predefinedfields')} />
            <CardContent>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell><FBSwitch id="emailreg" path={`${regpath}/email`} /></TableCell>
                    <TableCell><FBSwitch disabled={!regdata?.publicreglist} id="regfieldpublic" path={`${regpath}/emailpublic`} /></TableCell>
                    <TableCell><FBSwitch id="regfieldrequired" path={`${regpath}/emailrequired`} /></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><FBSwitch id="phonereg" path={`${regpath}/phone`} /></TableCell>
                    <TableCell><FBSwitch disabled={!regdata?.publicreglist} id="regfieldpublic" path={`${regpath}/phonepublic`} /></TableCell>
                    <TableCell><FBSwitch id="regfieldrequired" path={`${regpath}/phonerequired`} /></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><FBSwitch id="carreg" path={`${regpath}/car`} /></TableCell>
                    <TableCell><FBSwitch disabled={!regdata?.publicreglist} id="regfieldpublic" path={`${regpath}/carpublic`} /></TableCell>
                    <TableCell><FBSwitch id="regfieldrequired" path={`${regpath}/carrequired`} /></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
        <Grid>
          <Card>
            <CardHeader title={(<><span>{t('title.customfields')}</span><AddCustomFieldButton regpath={regpath} /></>)} />
            <CardContent>
              {regdata && <CustomFieldContents regdata={regdata} regpath={regpath} />}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default EventSettingsRegistration;
