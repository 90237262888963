import React, { useEffect, useMemo, useState } from "react";
import { useTSelector } from "rx/store";
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import NoSleep from "utils/NoSleep";
import StartBeeper from "utils/StartBeeper";
import { TeamList } from "rx/fbListSlices";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import Grid from "@mui/material/Grid";

const timetoshowafterrestart = 10000;

const StCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    fontSize: "18pt",
  },
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(1),
    fontSize: "25pt",
  },
}));

const StartList: React.FC<{
  startorder: [string, TeamList][];
  ct: number;
}> = ({ startorder, ct }) => {
  const { t } = useTranslation();
  return (
    <AutoSizer>
      {({ width, height }: Size) => {
        return (
          <TableContainer style={{ height: height, width: width }}>
            <Table stickyHeader sx={{ paddingRight: 1 }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("start.head.starttime")}</TableCell>
                  <TableCell>{t("start.head.timetostart")}</TableCell>
                  <TableCell>{t("start.head.team")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {startorder.map(([tid, team]) =>
                  team.starttime! < ct ? null : (
                    <TableRow key={tid}>
                      <StCell>
                        {dayjs(team.starttime).format("HH:mm:ss")}
                      </StCell>
                      <StCell>
                        {team.starttime! <= ct
                          ? "Startinud"
                          : dayjs
                              .duration(team.starttime! - ct)
                              .format("HH:mm:ss")}
                      </StCell>
                      <StCell>{team.name}</StCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }}
    </AutoSizer>
  );
};

const CurrentTime: React.FC<{ ct: number; label: string }> = ({
  ct,
  label,
}) => (
  <Card sx={{ width: 1 }}>
    <CardHeader title={label} />
    <CardContent>
      <Typography variant="h2" align="center">
        {dayjs(ct).format("HH:mm:ss")}
      </Typography>
    </CardContent>
  </Card>
);

const NextStarter: React.FC<{ starter: TeamList; ct: number }> = ({
  starter,
  ct,
}) => {
  let nexcol = "inherit";

  let s = starter.starttime! - ct;
  if (s <= 0) nexcol = "#44ea46";
  else if (s <= 10000) nexcol = "red";
  else if (s <= 60000) nexcol = "yellow";

  return (
    <Card variant="outlined" sx={{ backgroundColor: nexcol }}>
      <CardHeader
        title={<Typography variant="h3">{starter.name}</Typography>}
      />
      <CardContent>
        {starter.starttime ? (
          <Typography variant="h2" sx={{ fontWeight: "bold" }} align="center">
            {starter.starttime <= ct
              ? "Start"
              : dayjs.duration(starter.starttime - ct).format("HH:mm:ss")}
            <StartBeeper nextstartms={starter.starttime} />
          </Typography>
        ) : (
          "No start time"
        )}
      </CardContent>
    </Card>
  );
};
const StartOrderContents: React.FC = () => {
  const teamsList = useTSelector((state) => state.teamsList);
  const wide = useMediaQuery("(min-width:840px)");
  const { t } = useTranslation();
  const [ct, setCt] = useState(
    new Date().getTime() + (global.useTimeOffset ?? 0)
  );
  const fct = ct - (ct % 1000);
  useEffect(() => {
    const ti = setTimeout(
      () => {
        setCt(new Date().getTime() + (global.useTimeOffset ?? 0));
      },
      (1000 - (ct % 1000)) % 1000
    );
    return () => clearTimeout(ti);
  }, [ct]);
  const startorder = useMemo(() => {
    return Object.entries(teamsList)
      .filter(([_, team]) => !team.disabled && team.starttime)
      .sort(
        ([_aid, teama], [_bid, teamb]) => teama.starttime! - teamb.starttime!
      );
  }, [teamsList]);

  const nextstarter = startorder.find(
    ([_, team]) => team.starttime! + timetoshowafterrestart > ct
  );

  return (
    <>
      <NoSleep />
      {wide ? (
        <Grid
          container
          spacing={2}
          padding={2}
          height="100%"
          sx={{ backgroundColor: "white" }}
        >
          <Grid size={{ xs: 4 }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid size={{ xs: 12 }}>
                <CurrentTime ct={ct} label={t("label.clock")} />
              </Grid>
              <Grid size={{ xs: 12 }}>
                {nextstarter && (
                  <NextStarter ct={fct} starter={nextstarter[1]} />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid alignContent="stretch" height="100%" size={{ xs: 8 }}>
            <StartList startorder={startorder} ct={fct} />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          spacing={1}
          height="100%"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid>
            <CurrentTime ct={ct} label={t("label.clock")} />
          </Grid>
          <Grid>
            {nextstarter && <NextStarter ct={fct} starter={nextstarter[1]} />}
          </Grid>
          <Grid height="100%" width="100%">
            <StartList startorder={startorder} ct={fct} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default StartOrderContents;
